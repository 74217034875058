import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import {
  FormControl, TextField, Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import useStyles from './styles';

import {
  ENTRENAMIENTO, CHECK_IN, PRODUCT, GENERAL,
} from '../../../utils/const/formTypes';

import Snackbar from '../../../components/Snackbar';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';

import { getProjects } from '../../../requests/api/project';

function CreateTaskModal({
  defaultOpen, inputValues, setInputValues, setCurrentOpen, setEditView,
  duplicating, handleDuplicateTask, taskInfo,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);
  const [modalTitle, setModalTitle] = useState('task.taskForm.1.title');

  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (duplicating) {
      setInputValues({
        ...inputValues,
        title: taskInfo.title,
        type: {
          name: taskInfo.type.name,
          value: taskInfo.type.name,
        },
      });
    }
  }, []);

  const createSelectOptions = () => {
    const types = [ENTRENAMIENTO, CHECK_IN, PRODUCT, GENERAL].map((type) => ({
      name: type,
      value: type,
    }));

    types.unshift({
      name: t('task.taskForm.2.placeholderType'),
      value: '',
    });

    return types;
  };

  // Edit task info
  const handleChange = async (event) => {
    const {
      id, checked, name, value,
    } = event.target;

    if (!id) {
      let fixedValue = null;
      if (name === 'project') {
        fixedValue = projects.find((project) => project.value === value) !== null
          ? { name: projects.find((project) => project.value === value).name, value } : null;
      } else if (name === 'duplicateAssociations' || name === 'deactivatePreviousTask') {
        fixedValue = checked;
      } else {
        fixedValue = { name: value, value };
      }
      setInputValues({
        ...inputValues,
        [name]: fixedValue,
      });
    } else {
      if (id === 'title') {
        if (inputValues.title === '' && value === ' ') {
          return;
        }
        if (value.replace(/ /g, '') === '') {
          setInputValues({
            ...inputValues,
            [id]: '',
          });
          return;
        }
      }
      setInputValues({
        ...inputValues,
        [id]: value,
      });
    }
  };

  const handleNext = async () => {
    if (currentStep === 1 && inputValues.title === '') {
      setSeverity('error');
      setMessage(t('task.taskForm.errors.wrongName'));
      return;
    }
    if (currentStep === 2) {
      if (!inputValues.type.value) {
        setSeverity('error');
        setMessage(t('task.taskForm.errors.typeEmpty'));
        return;
      }

      if (!inputValues.project.value) {
        setSeverity('error');
        setMessage(t('task.taskForm.errors.projectEmpty'));
        return;
      }
      if (duplicating) {
        handleDuplicateTask();
      } else {
        setEditView(true);
      }
      return;
    }
    setModalTitle(`task.taskForm.${currentStep + 1}.title`);
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  useEffect(() => {
    // Gets projects data
    const getProjectsOptions = async () => {
      const response = await getProjects();

      if (response.status === 200) {
        const projectOptions = response.data.data.projects.map((project) => ({
          name: project.name,
          value: project.projectId,
        }));
        projectOptions.unshift({
          name: t('task.taskForm.2.placeholderProject'),
          value: '',
        });
        setProjects(projectOptions);
      }
    };

    if (currentStep === 2) {
      getProjectsOptions();
    }
  }, [currentStep, t]);
  return (
    <>
      <Modal
        title={t(modalTitle)}
        defaultOpen={defaultOpen}
        setCurrentOpen={setCurrentOpen}
      >
        <div className={styles.surveyForm}>
          {currentStep === 1 && (
            <FormControl fullWidth>
              <TextField
                id="title"
                value={inputValues.title}
                fullWidth
                label={t('task.taskForm.1.inputTitle')}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
          )}
          {currentStep === 2 && (
            <>
              <Select
                name="type"
                items={createSelectOptions()}
                label={t('task.taskForm.2.inputType')}
                value={inputValues.type.value}
                disabled={duplicating}
                onChange={handleChange}
                fullWidth
              />
              <Select
                name="project"
                items={projects}
                label={t('task.taskForm.2.placeholderProject')}
                value={inputValues.project.value}
                onChange={handleChange}
                fullWidth
              />
              {duplicating && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={inputValues.duplicateAssociations}
                      onChange={handleChange}
                      name="duplicateAssociations"
                      color="primary"
                    />
                    }
                    label={t('task.taskForm.2.duplicateAssociations')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={inputValues.deactivatePreviousTask}
                      onChange={handleChange}
                      name="deactivatePreviousTask"
                      color="primary"
                    />
                    }
                    label={t('task.taskForm.2.deactivatePreviousTask')}
                  />
                </>
              )}
            </>
          )}
          <div className={clsx(`${styles.buttonsContainer} ${styles.buttonsModal}`, {
            [styles.displacedButtons]: currentStep === 2,
          })}>
            <Button onClick={handleNext}>
              {t('task.taskForm.next')}
            </Button>
          </div>
          <div>
            <Snackbar
              open={message !== ''}
              message={message}
              severity={severity}
              onClose={() => setMessage('')}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateTaskModal;
