/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import Pagination from '../Pagination';

import Header from './Header';
import createXLSXTemplate from '../../utils/xlsx';

function applyPagination(ud, page, limit) {
  return ud.slice(page * limit, page * limit + limit);
}

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    color: theme.palette.secondary.gray,
  },
  fileChanges: {
    color: theme.palette.secondary.blue,
  },
  body: {
    height: '600px',
  },
  newValue: {
    color: 'green',
  },
  oldValue: {
    color: 'red',
  },
  noChangeValue: {
    color: theme.palette.primary.mainBlue,
  },
}));

function Changes({
  staticProperties, properties, tableHeaders, uploadDifference,
  setUploadDifference, updateFunction, uploadFunction,
  instructions, templateColumns, templateName, templateSheetName,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const paginatedUploadDifferences = applyPagination(uploadDifference, page, rowsPerPage);

  const displayColumnChange = (old, newVal, noChange, isMissingInFile) => {
    if (isMissingInFile) {
      return <div><p className={classes.oldValue}>{old ? old.trim() : old}</p></div>;
    }

    if (noChange) {
      return <div><p className={classes.noChangeValue}>{old ? old.trim() : old}</p></div>;
    }
    if (old) {
      const oldString = old.toString();
      if (newVal || newVal === '') {
        const newString = newVal.toString();
        if (oldString.trim() !== newString.trim()) {
          return (
            <div>
              <p className={classes.newValue}>{newString.trim()}</p>
              <p className={classes.oldValue}>{oldString.trim()}</p>
            </div>
          );
        }
        return (
          <div><p>{newString.trim()}</p></div>
        );
      }
      return <div><p>{oldString.trim()}</p></div>;
    }

    if (newVal) {
      return (<div><p className={classes.newValue}>{newVal}</p></div>);
    }

    return '';
  };

  // Function to download the Excel template
  const downloadTemplate = async () => {
    if (!templateColumns || templateColumns.length === 0) return [];

    return createXLSXTemplate(instructions, templateName, templateColumns, templateSheetName);
  };

  return (
    <div>
      <Box minWidth={700}>
        <Header
          uploadDifference={uploadDifference}
          setUploadDifference={setUploadDifference}
          updateFunction={updateFunction}
          uploadFunction={uploadFunction}
          templateColumns={templateColumns}
          downloadTemplate={downloadTemplate}
        />
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader) => (
                <TableCell
                  key={tableHeader.id}
                  className={classes.tableHeader}
                  align={tableHeader.id === 'active' ? 'center' : 'left'}
                  padding="normal"
                >
                  {tableHeader.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {!uploadDifference.length ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <h4 align="center" className={classes.fileChanges}>
                    {t('uploadFile.noFile')}
                  </h4>
                </TableCell>
              </TableRow>
            ) : null}
            {paginatedUploadDifferences.map((ud, index) => (
              <TableRow
                hover
                key={index}
              >
                {staticProperties ? staticProperties.map((staticProperty) => (
                  <TableCell key={staticProperty} className={ud.isNew ? classes.newValue : ''}>
                    {ud[staticProperty]}
                  </TableCell>
                )) : null}
                {properties.map((property) => (
                  <TableCell key={property} className={ud.isNew ? classes.newValue : ''}>
                    {ud.isNew ? ud[`new${property}`] : displayColumnChange(ud[`old${property}`], ud[`new${property}`], ud.noChange, ud.isMissingInFile)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        setPage={setPage}
        page={page}
        rowsPerPage={rowsPerPage}
        quantity={uploadDifference.length}
      />
    </div>
  );
}

export default Changes;
