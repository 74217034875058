import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconButton, Toolbar, TextField } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ReplyOutlined';
import Snackbar from '../../../components/Snackbar';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { SUPER_ADMIN } from '../../../utils/const/user';
import load from '../../../utils/load';
import { elements, props } from '../../../utils/const/association';
import { getGroupsPerPage } from '../../../requests/api/group';
import { getUsersPerPageByCompanyId } from '../../../requests/api/user';
// import { getClusterById } from '../../../requests/api/cluster';
import { postNewTaskInstance, putEditTaskInstance } from '../../../requests/api/taskInstance';
import { getLocationsProject } from '../../../requests/api/location-projects';
import { getAssociationCategoriesPerPage } from '../../../requests/api/category';

import TableDragAndDrop from './tableDragAndDrop';
import TaskAssociationConfirm from './taskAssociationConfirm';

import useStyles, { StyledTab, StyledTabs } from './styles';

function TaskAssociation({
  edit, name, project, taskId, setTaskId, setAssociate, taskInstances, setEdit,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);

  // table states
  const rowsPerPage = 10;
  const [selected, setSelected] = useState([]);
  const [pageIn, setPageIn] = useState(0);
  const [pageOut, setPageOut] = useState(0);
  const [quantityIn, setQuantityIn] = useState(0);
  const [quantityOut, setQuantityOut] = useState(0);
  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [out, setOut] = useState(true);
  const [ready, setReady] = useState(false);
  const [drag, setDrag] = useState(false);
  const [associationsDetail, setAssociationsDetail] = useState(false);
  const [update, setUpdate] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  // Filter states
  const [searchText, setSearchText] = useState({
    usersIn: '',
    usersNotIn: '',
    usersSearch: '',
    userGroupsIn: '',
    userGroupsNotIn: '',
    userGroupsSearch: '',
    locationsIn: '',
    locationsNotIn: '',
    locationsSearch: '',
    categoriesIn: '',
    categoriesNotIn: '',
    categoriesSearch: '',
  });
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);
  // snackbar states
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  // Data states
  const [info, setInfo] = useState({
    usersIn: [],
    usersNotIn: [],
    usersSearch: [],
    userGroupsIn: [],
    userGroupsNotIn: [],
    userGroupsSearch: [],
    locationsIn: [],
    locationsNotIn: [],
    locationsSearch: [],
    categoriesIn: [],
    categoriesNotIn: [],
    categoriesSearch: [],
  });

  const [associations, setAssociations] = useState([]);

  const [resetScale, setResetScale] = useState({
    name: t('task.association.resetTimes.placeholder'),
    value: '',
  });
  const [responsesExpected, setResponsesExpected] = useState(0);

  const resetTimes = useMemo(() => [
    { name: t('task.association.resetTimes.placeholder'), value: '' },
    { name: t('task.association.resetTimes.hourly'), value: 'hour' },
    { name: t('task.association.resetTimes.daily'), value: 'daily' },
    { name: t('task.association.resetTimes.weekly'), value: 'weekly' },
    { name: t('task.association.resetTimes.biweekly'), value: 'biWeekly' },
    { name: t('task.association.resetTimes.monthly'), value: 'monthly' },
    { name: t('task.association.resetTimes.quarterly'), value: 'quartely' },
    { name: t('task.association.resetTimes.annual'), value: 'annual' },
    { name: t('task.association.resetTimes.notClose'), value: 'notClose' },
  ], [t]);

  const handleBack = () => {
    setEdit(false);
    setTaskId(null);
    setAssociate(false);
  };

  const handleSaveChanges = async () => {
    const response = await putEditTaskInstance(associations);

    if (response.success) {
      setSeverity('success');
      setMessage(t('task.association.success.creating'));

      setTimeout(() => {
        setTaskId(null);
        setEdit(false);
        setAssociate(false);
      }, 500);
    } else {
      setSeverity('error');
      setMessage(t('task.association.errors.creating'));
    }
  };

  // Saves the association / create the task_instance
  const handleSaveNew = async () => {
    // If not assigned to any user does not create the taskInstance.
    if (!info.usersIn.length) {
      setSeverity('error');
      setMessage(t('task.association.errors.usersEmpty'));
      return;
    }

    if (!info.locationsIn.length) {
      setSeverity('error');
      setMessage(t('task.association.errors.locationsEmpty'));
      return;
    }

    if (!info.categoriesIn.length) {
      setSeverity('error');
      setMessage(t('task.association.errors.categoriesEmpty'));
      return;
    }

    if (!associationsDetail) {
      // First the associations are saved and the confirmation view is displayed.
      const associationsArray = info.usersIn.map((user) => {
        const categoriesWithCheck = info.categoriesIn.map((category) => {
          const locationsWithCheck = info.locationsIn.map((location) => {
            const locationWithCheck = {
              ...location,
              checked: true,
            };
            return locationWithCheck;
          });
          const categoryWithCheck = {
            ...category,
            checked: true,
            locations: locationsWithCheck,
          };
          return categoryWithCheck;
        });
        return ({
          checked: true,
          ...user,
          categories: categoriesWithCheck,
        });
      });

      setAssociations(associationsArray);
      setAssociationsDetail(true);
      return;
    }

    const newTaskInstances = [];

    associations.forEach((user) => {
      user.categories.forEach((category) => {
        category.locations.forEach((location) => {
          if (location.checked) {
            newTaskInstances.push({
              taskId,
              userId: user.id,
              locationId: location.id,
              active: true,
              resetScale: resetScale.value || null,
              expectedLocationResponses: responsesExpected || null,
              categoryCode: category.code || null,
              deleted: false,
            });
          }
        });
      });
    });

    const response = await postNewTaskInstance({ taskName: name, taskInstances: newTaskInstances });

    if (response.success) {
      setSeverity('success');
      setMessage(t('task.association.success.creating'));

      setTimeout(() => {
        setTaskId(null);
        setAssociate(false);
      }, 500);
    } else {
      setSeverity('error');
      setMessage(t('task.association.errors.creating'));
    }
  };

  // Reset Scale on change
  const handleOnChange = (event) => {
    setResetScale({
      name: event.target.name,
      value: event.target.value,
    });
  };

  // Search entities
  const requestSearch = async (pageOfTable,
    limit, searchValue, columnName, isOut, currentTab) => {
    let response;
    const idsIn = info[props[currentTab][0]].map((item) => item.id);
    const idsOut = info[props[currentTab][1]].map((item) => item.id);

    if (currentTab === 0) {
      response = await getGroupsPerPage('user-group', pageOfTable,
        limit, searchValue, columnName, 'noDeleted', idsIn, idsOut, isOut);
    } else if (currentTab === 2) {
      // const clusterId = cluster ? cluster.value.split('-')[0] : '';
      // response = await getClusterById(
      //   clusterId, pageOfTable, pageOfTable, limit,
      //   searchValue, locationsIdIn, locationsIdOut, IsOut,
      // );

      response = await getLocationsProject({
        page: pageOfTable,
        limit,
        searchValue,
        columnName,
        locationsIdIn: idsIn,
        locationsIdOut: idsOut,
        isOut,
        projectId: project,
      });
    } else if (currentTab === 3) {
      response = await getAssociationCategoriesPerPage({
        page: pageOfTable,
        limit,
        searchValue,
        categoriesIdIn: idsIn,
        categoriesIdOut: idsOut,
        isOut,
      });
    } else {
      response = await getUsersPerPageByCompanyId(pageOfTable, limit, searchValue, columnName,
        idsIn, idsOut, 'noDeleted', project.value);
    }
    if (response.success) {
      // Searches for the property to which the data belongs (in or out)
      const key = isOut ? props[currentTab][1] : props[currentTab][0];
      const keySearch = props[currentTab][2];
      let newRecords = response.data.data[elements[currentTab][0]];
      if (isOut) {
        const newQuantity = response.data.data[elements[currentTab][1]];

        if (info[props[currentTab][0]].length > 0) {
          const ids = info[props[currentTab][0]].map((entity) => entity.id);
          newRecords = newRecords.filter((record) => !ids.includes(record.id));
        }
        const paginationInElements = info[props[currentTab][0]].slice(
          pageIn * limit, (pageIn * limit) + limit,
        );
        setInfo({
          ...info,
          [key]: newRecords,
          [keySearch]: paginationInElements,
        });
        setQuantityOut(newQuantity);
        setQuantityIn(info[props[currentTab][0]].length);
      } else if (!isOut && info[props[currentTab][0]].length > 0) {
        let insideElements;
        let attributeToFilter;
        if (tab === 1) {
          attributeToFilter = 'fullName';
        } else {
          attributeToFilter = 'name';
        }
        if (searchValue !== '') {
          insideElements = info[props[currentTab][0]].filter(
            (record) => record[attributeToFilter].toLowerCase()
              .includes(searchValue.toLowerCase()),
          );
        } else {
          insideElements = info[props[currentTab][0]];
        }
        const paginationInElements = insideElements.slice(
          pageIn * limit, (pageIn * limit) + limit,
        );
        setInfo({
          ...info,
          [props[currentTab][2]]: paginationInElements,
        });
        setQuantityIn(insideElements.length);
      }

      if (tab === 0) setReady(true);
      setUpdate((prevState) => !prevState);
    }
  };

  // Change the tool tab
  const handleChangeTab = async (event, newTab) => {
    setLoading(true);
    setTab(newTab);
    setSelected([]);
    setOut(true);
    setFilter('');
    setPageIn(0);
    setPageOut(0);
    requestSearch(pageOut, rowsPerPage, '', false, true, newTab);
    setLoading(false);
  };

  useEffect(() => {
    if (taskInstances) {
      const userCategoryMap = new Map();

      taskInstances.forEach(({
        id, User, Location, Category,
      }) => {
        const fixedCategory = Category || { name: 'General', code: null };

        if (!userCategoryMap.has(User.id)) {
          userCategoryMap.set(User.id, {
            taskInstanceId: id,
            ...User,
            checked: !Location,
            categories: fixedCategory ? [{
              taskInstanceId: id,
              ...fixedCategory,
              checked: true,
              locations: Location ? [{
                taskInstanceId: id,
                ...Location,
                checked: true,
              }] : [],
            }] : [],
          });
        } else {
          const userEntry = userCategoryMap.get(User.id);
          const categoryIndex = userEntry.categories.findIndex(
            (category) => category.id === fixedCategory.id,
          );

          if (categoryIndex === -1) {
            userEntry.categories.push({
              taskInstanceId: id,
              ...fixedCategory,
              checked: true,
              locations: Location ? [{
                taskInstanceId: id,
                ...Location,
                checked: true,
              }] : [],
            });
          } else if (Location) {
            userEntry.categories[categoryIndex].locations.push({
              taskInstanceId: id,
              ...Location,
              checked: true,
            });
          }
        }
      });

      setAssociations(Array.from(userCategoryMap.values()));
    }
  }, [edit, update, taskInstances]);

  useEffect(() => {
    load(200, setLoading);

    if (ready && !info.usersNotIn.length && !edit) {
      requestSearch(pageOut, rowsPerPage, filter, column, out, tab);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    setOut(false);
    setPageChanged((prevState) => !prevState);
  }, [pageIn]);

  useEffect(() => {
    setOut(true);
    setPageChanged((prevState) => !prevState);
  }, [pageOut]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    let searchValue = '';
    if (out) {
      searchValue = searchText[props[tab][1]];
    } else {
      searchValue = searchText[props[tab][0]];
    }
    if (!edit) {
      requestSearch(pageOut, rowsPerPage, searchValue, column, out, tab);
    }
    // eslint-disable-next-line
  }, [pageChanged, rowsPerPage, account.user.role, column, filter, out, tab, drag]);

  return (
    <div className={styles.content}>
      <IconButton
        id="backButton"
        aria-label="back"
        className={styles.backButton}
        onClick={handleBack}
      >
        <BackIcon fontSize="small" />
        <span>{t('groups.actionButtons.back')}</span>
      </IconButton>
      <div className={styles.upperContainer}>
        <div>
          <h3>{name}</h3>
          <p>{project.name}</p>
        </div>
        <Button
          id="save-btn"
          variant="outlined"
          text={associationsDetail ? t('task.association.confirm') : t('task.association.createButton')}
          height="40px"
          width="220px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={edit ? handleSaveChanges : handleSaveNew}
          disabled={edit ? !associations.length : false}
        />
      </div>
      {edit ? (
        <TaskAssociationConfirm associations={associations} />
      ) : (
        <>
          <h4 className={styles.associationsTitle}>
            {t('task.association.confirmAssociations')}
          </h4>
          {resetTimes && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                name="reset-scale"
                items={resetTimes}
                value={resetScale ? resetScale.value : ''}
                onChange={handleOnChange}
              />
              <p className={styles.numberInput}>
                {t('task.association.repetitionMessage')}
              </p>
              <TextField
                style={{ width: '50px', paddingTop: '2.5%', height: '20px' }}
                id="exepected-location-responses"
                type="number"
                value={responsesExpected}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
                  if (sanitizedValue !== '' && Number(sanitizedValue) >= 0) {
                    setResponsesExpected(Number(sanitizedValue));
                  } else {
                    setResponsesExpected('');
                  }
                }}
                onKeyDown={(event) => {
                  // Evita que se ingresen los signos "+" y "-"
                  if (event.key === '+' || event.key === '-' || event.key === 'e') {
                    event.preventDefault();
                  }
                }}
              />

            </div>
          )}
          {associationsDetail ? (
            <div className={styles.associationsView}>
              <TaskAssociationConfirm associations={associations} />
            </div>
          ) : (
            <>
              <Toolbar className={styles.toolbarTable}>
                <StyledTabs
                  value={tab}
                  variant="scrollable"
                  onChange={handleChangeTab}
                >
                  <StyledTab label={t('task.association.tabs.userGroups')} />
                  <StyledTab label={t('task.association.tabs.users')} />
                  <StyledTab label={t('task.association.tabs.locations')} />
                  <StyledTab label={t('task.association.tabs.categories')} />
                </StyledTabs>
              </Toolbar>
              <TableDragAndDrop
                info={info}
                setInfo={setInfo}
                loading={loading}
                pageIn={pageIn}
                setPageIn={setPageIn}
                pageOut={pageOut}
                setPageOut={setPageOut}
                rowsPerPage={rowsPerPage}
                searchText={searchText}
                setSearchText={setSearchText}
                selected={selected}
                setSelected={setSelected}
                setFilter={setFilter}
                setColumn={setColumn}
                setOut={setOut}
                tab={tab}
                quantityIn={quantityIn}
                quantityOut={quantityOut}
                setDrag={setDrag}
                drag={drag}
              />
            </>
          )}
        </>
      )}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </div>
  );
}

export default TaskAssociation;
