import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// core
import {
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';

// Components
import { Autocomplete } from '@material-ui/lab';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';

import { getGeneralLocationsPerPage, postNewCompanyLocation } from '../../requests/api/location';

import useStyles from './styles';

const LocationSelect = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const currentCompany = useSelector((state) => state.company.companyId);

  const [inputValues, setInputValues] = useState({
    companyId: currentCompany,
    locationId: null,
  });
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [locations, setLocations] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const getLocationsRequest = async () => {
      const response = await getGeneralLocationsPerPage(0, 30, searchText);

      if (response.success) {
        setLocations(response.data.data);
      }
    };

    getLocationsRequest();
  }, [searchText]);

  const handleSave = async () => {
    try {
      const response = await postNewCompanyLocation(inputValues);
      if (response.status === 200) {
        setSeverity('success');
        setMessage(response.message);
        window.location.reload();
      } else {
        setSeverity('error');
        setMessage(response.data.errorMessage);
      }
    } catch (err) {
      setSeverity('error');
      setMessage('error');
      return (err);
    }
    return (false);
  };

  return (
    <div className={styles.selectModal}>
      <div>
        <FormControl>
          <FormLabel component="legend">{t('locations.locationSelect.name')}</FormLabel>
          <Autocomplete
            options={locations}
            getOptionLabel={(option) => option.name}
            fullWidth
            renderInput={(params) => <TextField {...params} variant="outlined" onChange={(event) => { setSearchText(event.target.value); }} />}
            onChange={
              (event, newValue) => {
                setInputValues({
                  ...inputValues,
                  locationId: (newValue === null) ? 0 : newValue.id,
                });
              }
            }
          />
        </FormControl>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          id="submit-btn"
          onClick={handleSave}
          text={t('locations.locationForm.button')}
          height="35px"
          width="100%"
          backgroundColor="#2C9DC9"
          color="#FFFFFF"
          borderRadius="10px"
        />
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div >
  );
};

export default LocationSelect;
