/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import {
  Button, Divider, FormControlLabel, Switch, IconButton,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  DragIndicator, Edit, ExpandLess, ExpandMore, VisibilityOutlined,
  ReplyOutlined,
} from '@material-ui/icons';

import {
  DragDropContext, Droppable, Draggable,
} from 'react-beautiful-dnd';

import useStyles from './styles';

// const
import {
  DROP_DOWN_MENU, END_PAGE, LONG_ANSWER, SIMPLE_SELECTION,
  SATISFACTION, SHORT_ANSWER, MULTIPLE_SELECTION, WELCOME_PAGE,
  YES_NO_QUESTION, NUMERIC_ANSWER, DATE, NPS_QUESTION, CONTENT,
  MULTIPLE_IMAGE_QUESTION, SIGNATURE_QUESTION, BARCODE_QUESTION,
  DECIMAL_ANSWER, PRICE, VIDEO,
} from '../../../utils/const/pageTypes';

import {
  EDIT_MODE,
} from '../../../utils/const/taskModes';

import questionValidator from '../../../utils/questionsValidator';

import { IMAGE, RESUME_PAGE } from '../../../utils/const/questions';
import { PRODUCT } from '../../../utils/const/formTypes';

// components
import SelectPage from '../../../components/Pages/SelectPage';
import WelcomePage from '../../../components/Pages/WelcomePage';
import ResumePage from '../../../components/Pages/ResumePage';
import SimpleSelection from '../../../components/Pages/SimpleSelection';
import MultipleSelection from '../../../components/Pages/MultipleSelection';
import ShortAnswer from '../../../components/Pages/ShortAnswer';
import DropDownMenu from '../../../components/Pages/DropDownMenu';
import LongAnswer from '../../../components/Pages/LongAnswer';
import Satisfaction from '../../../components/Pages/Satisfaction';
import ImageAnswer from '../../../components/Pages/ImageAnswer';
import NumericAnswer from '../../../components/Pages/NumericAnswer';
import Snackbar from '../../../components/Snackbar';
import DateAnswer from '../../../components/Pages/DateAnswer';
import QuestionLogic from '../../QuestionLogic';
import YesNoQuestion from '../../../components/Pages/YesNoQuestion';
import NpsQuestion from '../../../components/Pages/NpsQuestion';
import MultipleImageQuestion from '../../../components/Pages/MultipleImageQuestion';
import SignatureQuestion from '../../../components/Pages/SignatureQuestion';
import BarcodeQuestion from '../../../components/Pages/BarcodeQuestion';
import SettingsModal from '../settingsModal/settingModal';
import DecimalAnswer from '../../../components/Pages/DecimalAnswer';
import Price from '../../../components/Pages/Price';
import Video from '../../../components/Pages/Video';

// requests
import { postNewSurvey } from '../../../requests/api/survey';

function DuplicateTask({
  questions, project, title, formType, setEditView, setInputValues, setModalOpen,
  setTaskInfo, setQuestions, taskInfo, setDuplicateTask, inputValues, taskId, setTaskId,
  setCurrentOpen, vertical, valuesProp, setValuesProp, switchStock, setSwitchStock,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  // snackbar status
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [values, setValues] = useState({
    title,
    formType,
    project,
    questions: [],
  });

  const [verticalForm, setVerticalForm] = useState(vertical);

  const [openConfigs, setOpenConfigs] = useState(false);
  const [georeference, setGeoreference] = useState(true);
  const [viewDetail, setViewDetail] = useState(null);
  const generalView = false;
  // const [generalView, setGeneralView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [pages, setPages] = useState([
    {
      identifier: 0,
      hiddenIdentifier: 0,
      type: null,
      name: '',
      required: false,
    },
  ]);
  const [revisionPolicy, setRevisionPolicy] = useState({
    name: t('task.taskEdition.requiresRevision.placeholder'),
    value: '',
  });

  useEffect(() => {
    setGeoreference(taskInfo.requiredGeoreference);
    setRevisionPolicy({
      name: t('task.taskEdition.revisionPolicy.placeholder'),
      value: taskInfo.revisionPolicy,
    });
  }, [taskInfo]);

  useEffect(() => {
    const parseQuestionChoices = (questionChoices) => {
      const optionsArray = [];
      let identifierChoice = 0;
      questionChoices.forEach((questionChoice) => {
        identifierChoice += 1;
        optionsArray.push({
          title: questionChoice.title,
          type: questionChoice.type,
          identifier: identifierChoice,
          id: questionChoice.id,
        });
      });

      return optionsArray;
    };

    setVerticalForm(vertical);
    for (let i = 0; i < questions.length; i += 1) {
      let memoryQuantity = null;
      let memoryUnit = null;
      if (questions[i].minMemory) {
        [memoryQuantity, memoryUnit] = questions[i].minMemory.split(' ');
      }

      if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(questions[i].type)) {
        const kpiDetailsPage = [];
        const shareKpi = {};
        questions[i].TeleauditKpis.forEach((teleauditKpi) => {
          if (teleauditKpi.KpiDetail.teleauditType === 'binary') {
            const kpi = {
              title: teleauditKpi.KpiDetail.name,
              description: teleauditKpi.KpiDetail.description,
              teleauditType: teleauditKpi.KpiDetail.teleauditType,
            };
            kpiDetailsPage.push(kpi);
          } else {
            shareKpi.teleauditType = teleauditKpi.KpiDetail.teleauditType;
            shareKpi.target = teleauditKpi.KpiDetail.target;
          }
        });
        // eslint-disable-next-line no-param-reassign
        pages[i] = {
          ...pages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          questionChoices: questions[i].QuestionChoices || null,
          binaryKpis: kpiDetailsPage,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          shareKpi,
          questionLogic: [{
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null, // revisar PENDIENTE
            nextQuestionIdentifier: `${(i + 2)}`,
          }],
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      } else {
        // const questionChoicesArray = [];
        if (questions[i].isSwitchStock) {
          setSwitchStock(i + 1);
        }
        const parseQuestionLogic = [];
        questions[i].QuestionChoices = [...questions[i].QuestionChoices].sort(
          (a, b) => a.id - b.id,
        );
        if (questions[i].OriginalQuestionLogics.length > 0) {
          questions[i].OriginalQuestionLogics.forEach((questionLogic) => {
            const nextId = questions.findIndex(
              (question) => question.id === parseInt(questionLogic.nextQuestionId, 10),
            );
            const questionChoiceIdentifier = questions[i].QuestionChoices.findIndex(
              (questionChoice) => questionChoice.id === questionLogic.questionChoiceId,
            );
            if (nextId > -1 && questionChoiceIdentifier > -1) {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: `${(questionChoiceIdentifier + 1)}`,
                nextQuestionIdentifier: questions[nextId].position.toString(),
              });
            } else if (nextId > -1) {
              // console.log('Aqui');
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(nextId + 1)}`,
              });
            } else {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(i + 2)}`,
              });
            }
          });
        } else {
          parseQuestionLogic.push({
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null,
            nextQuestionIdentifier: `${(questions.length + 1)}`,
          });
        }
        // eslint-disable-next-line no-param-reassign
        pages[i] = {
          ...pages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          questionChoices: questions[i].QuestionChoices
            ? parseQuestionChoices(questions[i].QuestionChoices)
            : null,
          questionLogic: parseQuestionLogic,
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          isSwitchStock: questions[i].isSwitchStock,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      }
    }
    setUpdate(!update);
  }, [questions]);

  // const [tab, setTab] = useState(0);
  const [selectedPage, setSelectedPage] = useState(null);
  const [openConnections, setOpenConnections] = useState(false);

  // Change the tool tab
  // const handleChangeTab = (event, newTab) => {
  //   setTab(newTab);
  // };

  // Select page
  const handleSelectPage = (event) => {
    const [type, identifier] = event.currentTarget.id.split('-');

    if (type === 'null') return;

    setSelectedPage(identifier);
  };

  // Adds a new page
  const handleAddSection = () => {
    setPages([
      ...pages,
      {
        identifier: `${pages.length + 1}`,
        hiddenIdentifier: `${pages.length + 1}`,
        type: null,
      },
    ]);
  };

  // Handles the status that indicates whether the question is required or not
  const handleSwitch = (event) => {
    setUpdate((prevState) => !prevState);
    const { name, checked } = event.target;

    pages[name].required = checked;
  };

  // Relocates changed pages in the array
  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const copiedItems = [...pages];
    for (let i = 0; i < copiedItems.length; i += 1) {
      copiedItems[i].hiddenIdentifier = `${(i + 1)}`;
    }
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    // Updates identifier question, ignore informative pages
    for (let i = 0; i < copiedItems.length; i += 1) {
      copiedItems[i].identifier = `${(i + 1)}`;
    }
    for (let j = 0; j < copiedItems.length; j += 1) {
      const questionLogicCopiedItem = copiedItems[j].questionLogic;
      if (questionLogicCopiedItem) {
        for (let i = 0; i < questionLogicCopiedItem.length; i += 1) {
          if (questionLogicCopiedItem[i].questionChoiceIdentifier) {
            const newIdToNextQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].nextQuestionIdentifier,
            );
            const idQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].questionIdentifier,
            );
            if (newIdToNextQuestion && idQuestion) {
              // eslint-disable-next-line max-len
              copiedItems[j].questionLogic[i].nextQuestionIdentifier = newIdToNextQuestion.identifier;
              copiedItems[j].questionLogic[i].questionIdentifier = idQuestion.identifier;
            }
          } else {
            copiedItems[j].questionLogic[i].questionIdentifier = `${(j + 1)}`;
            copiedItems[j].questionLogic[i].nextQuestionIdentifier = `${(j + 2)}`;
          }
        }
      }
    }
    const switchStockIndex = copiedItems.findIndex((ci) => ci.isSwitchStock);

    if (switchStockIndex !== -1) {
      setSwitchStock(copiedItems[switchStockIndex].identifier, 10);
    }
    copiedItems.forEach((copiedItem) => {
      delete copiedItem.hiddenIdentifier;
    });
    setPages([
      ...copiedItems,
    ]);
  };

  const handleSaveSurvey = async () => {
    const questionTypesFixed = [];

    let position = 0;
    let validator = 0;
    let revisionPolicyValidator = revisionPolicy.value;
    if (!revisionPolicyValidator) {
      setSeverity('error');
      setMessage(t('task.saveErrors.noRequiresRevision'));
      return;
    }

    if (valuesProp.isSwitchStock && switchStock == null) {
      setSeverity('error');
      setMessage(t('task.saveErrors.switchStockRequiredQuestion'));
      return;
    }

    pages.forEach((page) => {
      if (validator !== 0) return;
      position += 1;
      if (!page.type) return;

      page.position = position;
      validator = questionValidator(page, formType.name);
      if (validator !== 0) {
        setSeverity('error');
        switch (validator) {
          case 1:
            setMessage(`${t('task.saveErrors.title')} ${position}`);
            break;
          case 2:
            setMessage(`${t('task.saveErrors.binaryKpis')} ${position}`);
            break;
          case 4:
            setMessage(`${t('task.saveErrors.correctQuestionChoices')} ${position}`);
            break;
          default:
            setMessage(`${t('task.saveErrors.questionChoices')} ${position}`);
            break;
        }
        return;
      }
      const nextQuestionIdentifiers = page.questionLogic.map(
        (questionLogic) => questionLogic.nextQuestionIdentifier,
      );

      const index = nextQuestionIdentifiers.findIndex((nqi) => nqi === 'end' || nqi === '');

      if (index !== -1) {
        page.questionLogic[index].nextQuestionIdentifier = pages.length;
      }
      if (revisionPolicyValidator !== 'yes') {
        if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(page.type)) {
          if (page.requiresTeleaudit) {
            revisionPolicyValidator = 'yes';
          }
        }
      }
      page.isSwitchStock = page.identifier === switchStock;
      page.minMemory = page.memoryQuantity && page.memoryUnit ? `${page.memoryQuantity} ${page.memoryUnit}` : null;
      delete page.memoryQuantity;
      delete page.memoryUnit;
      delete page.hiddenIdentifier;
      delete page.databaseId;
      questionTypesFixed.push(page);
    });
    if (validator === 0) {
      const createSurveyResponse = await postNewSurvey({
        title: inputValues.title,
        questions: questionTypesFixed,
        projectId: project,
        type: formType.name,
        requiredGeoreference: georeference,
        revisionPolicy: revisionPolicyValidator,
        vertical: inputValues.vertical,
        switchStock: valuesProp.isSwitchStock,
        productInformation: valuesProp.productInformation,
        duplicateAssociationsFrom: inputValues.duplicateAssociations && taskId ? taskId : null,
        deactivatePreviousTask: inputValues.deactivatePreviousTask,
      });

      if (createSurveyResponse.success) {
        setSeverity('success');
        setCurrentOpen(false);
        setModalOpen(false);
        setMessage(t('task.messages.success.surveyCreate'));
        setEditView(false);
        setInputValues({
          title: '',
          type: { name: '', value: '' },
          project: { name: '', value: '' },
        });
        setVerticalForm(false);
        setDuplicateTask(false);
        setTaskId(null);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.error.surveyCreate'));
      }
    }
  };

  // const handleGeneralView = () => {
  //   setGeneralView((prevState) => !prevState);
  // };

  const handleViewQuestion = (event) => {
    const { id } = event.currentTarget;

    if (id === viewDetail) {
      setViewDetail(null);
      return;
    }

    setViewDetail(id);
  };

  useEffect(() => () => {
    setValues({
      ...values,
      questions: pages,
    });
    // eslint-disable-next-line
  }, [pages, openConnections, update]);

  const handleOpenConfigs = () => {
    setOpenConfigs(!openConfigs);
  };

  const handleBack = () => {
    setCurrentOpen(false);
    setEditView(false);
    setInputValues({
      title: '',
      type: { name: '', value: '' },
      project: { name: '', value: '' },
    });
    setDuplicateTask(false);
    setTaskInfo({
      project: '',
      title: '',
      type: '',
    });
    setQuestions([]);
  };

  const handleSwitchStock = (event) => {
    const { checked } = event.target;

    if (checked) {
      setValuesProp({
        ...valuesProp,
        isSwitchStock: checked,
        productInformation: false,
      });
    } else {
      setValuesProp({
        ...valuesProp,
        isSwitchStock: checked,
      });
    }
  };

  const handleSwitchProductInformation = (event) => {
    const { checked } = event.target;

    if (checked) {
      setValuesProp({
        ...valuesProp,
        isSwitchStock: false,
        productInformation: checked,
      });
    } else {
      setValuesProp({
        ...valuesProp,
        productInformation: checked,
      });
    }
  };

  const handleDeleteQuestion = (event) => {
    const { id } = event.currentTarget;

    const copyPages = pages;
    const pageKeys = Object.keys(copyPages);

    const newPages = [];

    pageKeys.forEach((pageIdentifier) => {
      if (pageIdentifier < (id - 1)) newPages.push(copyPages[pageIdentifier]);
      if (pageIdentifier > (id - 1)) {
        copyPages[pageIdentifier].identifier = pageIdentifier;
        const questionLogic = copyPages[pageIdentifier].questionLogic.find(
          (ql) => ql.questionChoiceIdentifier === null,
        );
        if (questionLogic) {
          questionLogic.questionIdentifier = pageIdentifier;
          questionLogic.nextQuestionIdentifier = (Number.parseInt(pageIdentifier, 10) + 1)
            .toString();
        }
        newPages.push(copyPages[pageIdentifier]);
      }
    });

    setPages(newPages);
  };

  const handleCreateConnections = () => {
    let errors = '';
    pages.forEach((page) => {
      const identifier = page.identifier === 0 ? 1 : page.identifier;
      const validator = questionValidator(page, formType.name);
      if (validator !== 0) {
        setSeverity('error');
        switch (validator) {
          case 1:
            errors += `${t('task.saveErrors.title')} ${identifier}\n`;
            break;
          case 2:
            errors += `${t('task.saveErrors.binaryKpis')} ${identifier}\n`;
            break;
          case 4:
            errors += `${t('task.saveErrors.correctQuestionChoices')} ${identifier}\n`;
            break;
          case 5:
            errors += `${t('task.saveErrors.questionType')} ${identifier}\n`;
            break;
          default:
            errors += `${t('task.saveErrors.questionChoices')} ${identifier}\n`;
            break;
        }
      }
    });

    if (errors === '') {
      setOpenConnections(true);
    } else {
      setMessage(errors);
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <IconButton
            id="backButton"
            aria-label="back"
            className={styles.backButton}
            onClick={handleBack}
          >
            <ReplyOutlined fontSize="small" />
            <span>{t('groups.actionButtons.back')}</span>
          </IconButton>
          <TextField
            value={inputValues.title}
            onChange={(e) => setInputValues({
              ...inputValues, title: e.target.value,
            })}
          />
          <p>
            {project ? project.name : null}
          </p>
        </div>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleCreateConnections}
                disabled={questions.length < 2
                  || (questions.length === 2 && !questions[1].type)
                  || !questions[questions.length - 1].type}
              >
               {t('task.questionLogic.connections')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleOpenConfigs}
              >
                {t('task.taskEdition.settings')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleSaveSurvey}
                disabled={pages.length === 1 && !pages[0].type}
              >
                {t('task.taskEdition.manageButton')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={styles.surveyContent}>
        <div className={styles.editionArea}>
          {formType.name === PRODUCT && (
            <div>
              <FormControlLabel
              control={
                <Switch
                  name='is_switch_stock'
                  className={styles.switch}
                  onChange={handleSwitchStock}
                  checked={valuesProp.isSwitchStock}
                  disabled={pages.findIndex((p) => p.type === YES_NO_QUESTION) === -1
                    || valuesProp.productInformation}
                />
              }
              label={t('task.taskEdition.stockSwitch')}
            />
            <FormControlLabel
              control={
                <Switch
                  name='product_information'
                  className={styles.switch}
                  onChange={handleSwitchProductInformation}
                  checked={valuesProp.productInformation}
                  disabled={valuesProp.isSwitchStock}
                />
              }
              label={t('task.taskEdition.productInformationSwitch')}
            />
          </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {pages.map((page, index) => (
                    <Draggable draggableId={`${index}`} index={index} key={index}>
                      {(prov) => (
                        <div
                          id={`${page.type}-${index}`}
                          onClick={handleSelectPage}
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                        >
                          <ExpandLess
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx(styles.pageInfo, {
                              [styles.selectedPageGeneralView]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                              [styles.generalViewPageTitle]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            <div>
                              {generalView && <DragIndicator />}
                              <p className={styles.pageTitle}>
                                {t('task.taskEdition.page')} {index + 1}
                                {page.type ? `: ${page.name}` : ''}
                              </p>
                            </div>
                            <div>
                              {page.type && generalView ? (
                                <>
                                  <Edit fontSize="small" />
                                  <Button
                                    id={index}
                                    onClick={handleViewQuestion}
                                  >
                                    <VisibilityOutlined className={styles.viewQuestion} />
                                  </Button>
                                </>
                              ) : null}
                              {page.type && !generalView
                                // eslint-disable-next-line max-len
                                && ![WELCOME_PAGE, END_PAGE, CONTENT, RESUME_PAGE].includes(page.type) ? (
                                <>
                                  <p className={styles.pageTitle}>{t('task.taskEdition.obligatory')}</p>
                                  <FormControlLabel
                                    control={
                                      <Switch name={`${index}`} className={styles.switch} onChange={handleSwitch} checked={page.required} />
                                    }
                                    label=""
                                  />
                                </>
                                ) : null}
                            </div>
                          </div>
                          <ExpandMore
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx({
                              [styles.selectedPageStyle]:
                                selectedPage === `${index}`,
                              [styles.hideContent]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            {([WELCOME_PAGE, END_PAGE, CONTENT].includes(page.type))
                              && <WelcomePage
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            }
                            {page.type === RESUME_PAGE && (
                              <ResumePage
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SIMPLE_SELECTION && (
                              <>
                                <SimpleSelection
                                  identifier={page.identifier}
                                  pages={pages}
                                  mode={EDIT_MODE}
                                  setUpdate={setUpdate}
                                  formType={formType}
                                  handleDeleteQuestion={handleDeleteQuestion}
                                />
                              </>
                            )}
                            {page.type === MULTIPLE_SELECTION && (
                              <MultipleSelection
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SHORT_ANSWER && (
                              <ShortAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === LONG_ANSWER && (
                              <LongAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === DROP_DOWN_MENU && (
                              <DropDownMenu
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SATISFACTION && (
                              <Satisfaction
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === IMAGE && (
                              <ImageAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === YES_NO_QUESTION && (
                              <YesNoQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                switchStock={switchStock}
                                setSwitchStock={setSwitchStock}
                                isSwitchStock={valuesProp.isSwitchStock}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === DATE && (
                              <DateAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === NUMERIC_ANSWER && (
                              <NumericAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === DECIMAL_ANSWER && (
                              <DecimalAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === NPS_QUESTION && (
                              <NpsQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === MULTIPLE_IMAGE_QUESTION && (
                              <MultipleImageQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SIGNATURE_QUESTION && (
                              <SignatureQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === BARCODE_QUESTION && (
                              <BarcodeQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === PRICE && (
                              <Price
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === VIDEO && (
                              <Video
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {!page.type && (
                              <SelectPage
                                setPages={setPages}
                                pages={pages}
                                mode={EDIT_MODE}
                                formType={formType}
                                identifier={page.identifier}
                                setSwitchStock={setSwitchStock}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            className={styles.button}
            onClick={handleAddSection}
          >
            {t('task.taskEdition.addPageButton')}
          </Button>
        </div>
        {/* <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <StyledTabs
              value={tab}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <StyledTab label={t('task.taskEdition.tabOne')} />
              <StyledTab label={t('task.taskEdition.tabTwo')} />
            </StyledTabs>
            <div className={styles.toolsContent}>
              {tab === 1 ? (
                <div className={styles.connectionButton}>
                  <p>
                    <b>{t('task.questionLogic.content')}</b>
                    <br />
                    {t('task.questionLogic.connections')}
                  </p>
                  <IconButton
                    aria-label="connections"
                    onClick={handleCreateConnections}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              ) : null}
              {!selectedPage && tab !== 1 ? (
                <p>
                  <em>{t('task.taskEdition.toolsPlaceholder')}</em>
                </p>
              ) : null}
              <div className={styles.buttonsToolsBar}>
                <Button>
                  <VisibilityOutlined />
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleGeneralView}
                >
                  {generalView ? t('task.taskEdition.extendedViewButton')
                    : t('task.taskEdition.generalViewButton')}
                </Button>
              </div>
            </div>
          </Paper>
        </div> */}
      </div>
      {openConnections ? (
        <QuestionLogic
          open={openConnections}
          setOpen={setOpenConnections}
          inputValues={pages}
          setInputValues={setPages}
          edit={true}
          view={false}
        />
      ) : null}
      {openConfigs
        ? <SettingsModal
          openConfigs={openConfigs}
          setOpenConfigs={setOpenConfigs}
          georeference={georeference}
          setGeoreference={setGeoreference}
          revisionPolicy={revisionPolicy}
          setRevisionPolicy={setRevisionPolicy}
          verticalForm={verticalForm}
          setVerticalForm={setVerticalForm}
          formType={formType}
        />
        : null}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default DuplicateTask;
