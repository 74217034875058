import React from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Grid, FormControlLabel, Switch,
} from '@material-ui/core';

import Select from '../../../components/Select';
import Modal from '../../../components/Modal';

import useStyles from './styles';
import { PRODUCT } from '../../../utils/const/formTypes';

const SettingsModal = ({
  openConfigs, georeference, revisionPolicy, setGeoreference,
  setRevisionPolicy, setOpenConfigs, verticalForm, setVerticalForm, formType,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const revisionOptions = [
    {
      name: t('task.taskEdition.revisionPolicy.placeholder'),
      value: '',
    },
    {
      name: t('task.taskEdition.revisionPolicy.yes'),
      value: 'yes',
    },
    {
      name: t('task.taskEdition.revisionPolicy.no'),
      value: 'no',
    },
    {
      name: t('task.taskEdition.revisionPolicy.random'),
      value: 'random',
    },
  ];

  const handleOnChangeRevision = (event) => {
    setRevisionPolicy(event.target);
  };

  const handleGeoreference = () => {
    setGeoreference(!georeference);
  };

  const handleOpenConfigs = () => {
    setOpenConfigs(!openConfigs);
  };

  return (
    <>
      <div className={styles.formWrapper}>
        <Modal title={t('task.taskEdition.settings')} defaultOpen={openConfigs} setCurrentOpen={handleOpenConfigs}>
          <div className={styles.formWrapper}>
            <Grid container spacing={2} >
            {/* <Grid container spacing={2} alignItems='flex-end'> */}
              <Grid item xs={4}>
                <FormControlLabel
                  className={styles.switch}
                  control={<Switch checked={georeference} onChange={handleGeoreference} color="primary"/>}
                  label={t('task.taskEdition.georeference')}
                />
              </Grid>
              { revisionOptions && (
                <Grid item xs={8}>
                  <Grid container className={styles.select}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={5} className={styles.label}>
                      <p className={styles.revisionPolicy}>
                        {t('task.taskEdition.revisionPolicyQuestion')}
                      </p>
                    </Grid>
                    <Grid item xs={7}>
                      <Select
                        name='requires-revision'
                        items={revisionOptions}
                        value={revisionPolicy ? revisionPolicy.value : ''}
                        onChange={handleOnChangeRevision}
                        />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControlLabel
                  className={`${styles.switch} ${styles.switchVerticalOption}`}
                  control={<Switch checked={formType.name === PRODUCT || verticalForm} onChange={() => setVerticalForm(!verticalForm)} color="primary"/>}
                  label={t('task.taskEdition.verticalForm')}
                  disabled={formType.name === PRODUCT}
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SettingsModal;
