import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// core
import {
  Typography,
  Box,
  Table,
  TableCell,
  TableRow,
  CircularProgress,
  Paper,
  TableBody,
  IconButton,
  TableHead,
  TableContainer,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

// components
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import useStyles from './styles';
// Cambiar endpoint
import { getTaskResponsesTeleaudit } from '../../requests/api/taskResponse';
import { UNAUTHORIZED } from '../../utils/const/http';

import Snackbar from '../../components/Snackbar';
import Pagination from '../../components/Pagination';

// eslint-disable-next-line no-shadow
const PreTeleaudit = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState(null);
  const rowsPerPage = 10;
  const [taskResponsesQuantity, setTaskResponsesQuantity] = useState(0);

  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const tableHeaders = [
    {
      id: 'locationName',
      label: <b>{t('teleaudit.index.locationName')}</b>,
    },
    {
      id: 'taskName',
      label: <b>{t('teleaudit.index.taskName')}</b>,
    },
    {
      id: 'category',
      label: <b>{t('teleaudit.index.category')}</b>,
    },
    {
      id: 'userName',
      label: <b>{t('teleaudit.index.userName')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('teleaudit.index.toTeleaudit')}</b>,
    },
  ];

  const requestSearch = async (pageOfTable, limit) => {
    // A este endpoint tengo que agregarle la cantida de task Responses
    const response = await getTaskResponsesTeleaudit(pageOfTable, limit);
    if (response.success) {
      setRowsToShow(response.data.data.taskResponses.rows);
      setTaskResponsesQuantity(response.data.data.taskResponses.count);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/dashboard';
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    requestSearch(page, rowsPerPage);
  }, [page, rowsPerPage, taskResponsesQuantity]);

  const content = () => {
    if (!rowsToShow) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <h4>{t('teleaudit.index.noTasks')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >{row.TaskInstance.Survey
              && <>
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.locationName}
                  width = "20%"
                >{row.TaskInstance.Location ? row.TaskInstance.Location.name : t('qa.noLocationName')}</TableCell>
              <TableCell align="left">{row.TaskInstance.Survey ? row.TaskInstance.Survey.title : t('qa.noTaskName')}</TableCell>
              <TableCell align="left">{row.TaskInstance.Category ? `${row.TaskInstance.Category.name} (${row.TaskInstance.Category.code})` : 'General'}</TableCell>
              <TableCell align="left">{row.TaskInstance.User ? row.TaskInstance.User.fullName : t('qa.noUserName')}</TableCell>
              <TableCell align="center">
                <IconButton component={Link} to={`teleaudit/${row.id}`}>
                  <RemoveRedEyeIcon/>
                </IconButton>
              </TableCell>
              </>
            }
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
          {t('teleaudit.index.subtitle1')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
          {t('teleaudit.index.subtitle2')}
          </Typography>
        </Box>
      </div>
      <Paper className={styles.taskResponseTable}>
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((tableHeader) => (
                      <TableCell
                        key={tableHeader.id}
                        className={clsx({
                          [styles.tableHeader]: true,
                          [styles.taskNameHeader]: ['locationName', 'taskName', 'userName', 'category'].includes(tableHeader.id),
                        })}
                        padding="normal"
                        align="left"
                      >
                        {tableHeader.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={9} className={styles.progress}>
                        <CircularProgress />
                        <h4>{t('qa.loading')}</h4>
                      </TableCell>
                    </TableRow>
                  ) : content()}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={taskResponsesQuantity}
            />
          </>
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
};

export default PreTeleaudit;
