import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
// core
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
import { withStyles, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
// icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTreeOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccountOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import StoreIcon from '@material-ui/icons/StoreOutlined';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import BallotIcon from '@material-ui/icons/BallotOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupsIcon from '@material-ui/icons/GroupWorkOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ProductInfoIcon from '@material-ui/icons/ListAltOutlined';

import useStyles from './styles';
import { CLIENT_EMPLOYEE, SUPER_ADMIN } from '../../../utils/const/user';
import authService from '../../../services/authService';
import OutlinedSelect from '../../OutlinedSelect';

const DrawerTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
    textAlign: 'center',
    width: '95px',
    maxWidth: '100%',
    fontSize: '1em',
  },
  arrow: {
    color: theme.palette.primary.main,
    border: '0px',
  },
}))(Tooltip);

export default function MiniDrawer({
  companies, handleCompanyChange, currentCompany, ticketsQuantity,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    authService.logout();
    window.location.href = '/login';
  };

  const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      right: 20,
      top: 4,
      border: '2px',
      padding: '4px',
    },
  }));

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={clsx(classes.toolbar, {
          [classes.toolbarClose]: !open,
        })}>
          <img
            src="/TCGHRES2021BACK2.png"
            alt="TCG logo"
            className={clsx(classes.image, {
              [classes.hide]: !open,
            })}
          />
          <Button
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.image, {
              [classes.hide]: open,
            })}
          >
            <img
              className={classes.image}
              src="/TCGHRES2021BACK2DRAW.png"
              alt="TCG logo"
            />
          </Button>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: !open,
            })}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.containerTabItems}>
          <div>
            <p className={clsx(classes.tabTitle, {
              [classes.tabTitleOpen]: open,
              [classes.tabTitleClose]: !open,
            })}
            >{t('layout.tabsTitle.company')}</p>
            <div className={classes.companySelector}>
              <OutlinedSelect
                items={companies}
                fullWidth={true}
                value={currentCompany}
                onChange={handleCompanyChange}
              />
            </div>
            <Divider className={clsx({
              [classes.dividerOpen]: open,
              [classes.dividerClose]: !open,
            })}
            /> <p className={clsx(classes.tabTitle, {
              [classes.tabTitleOpen]: open,
              [classes.tabTitleClose]: !open,
            })}
            >{t('layout.tabsTitle.menu')}</p>
            <List>
              {/* HOME */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.home') : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/home"
                  className={window.location.pathname === '/home' && open && classes.listItemSelected}
                >
                  <ListItemIcon>
                    <DashboardIcon className={clsx({
                      [classes.iconOpen]: open,
                      [classes.iconClose]: !open,
                      [classes.iconSelected]: window.location.pathname === '/home' && !open,
                    })}
                    /></ListItemIcon>
                  <ListItemText
                    primary={t('layout.tooltips.home')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* TICKETS */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.supportTickets') : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/support"
                  className={window.location.pathname === '/support' && open && classes.listItemSelected}
                >
                  <StyledBadge
                    badgeContent={ticketsQuantity}
                    color='error'
                    overlap="rectangular"
                  >
                    <ListItemIcon>
                      <AssignmentIcon className={clsx({
                        [classes.iconOpen]: open,
                        [classes.iconClose]: !open,
                        [classes.iconSelected]: window.location.pathname === '/support' && !open,
                      })}
                      />
                    </ListItemIcon>
                  </StyledBadge>
                  <ListItemText
                    primary={t('layout.tabs.supportTickets')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* CHALLENGES */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Challenges' : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/challenges"
                  className={window.location.pathname === '/challenges' && open && classes.listItemSelected}
                >
                  <ListItemIcon>
                    <DonutLargeIcon className={clsx({
                      [classes.iconOpen]: open,
                      [classes.iconClose]: !open,
                      [classes.iconSelected]: window.location.pathname === '/challenges' && !open,
                    })}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Challenges'}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* BI REPORTS */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.biReports') : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/reports"
                  className={window.location.pathname === '/reports' && open && classes.listItemSelected}
                >
                  <ListItemIcon>
                    <AssessmentIcon className={clsx({
                      [classes.iconOpen]: open,
                      [classes.iconClose]: !open,
                      [classes.iconSelected]: window.location.pathname === '/reports' && !open,
                    })}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('layout.tabs.biReports')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* GANTT */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Gantt' : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/gantts"
                  className={window.location.pathname === '/gantts' && open && classes.listItemSelected}
                >
                  <ListItemIcon>
                    <AccountTreeIcon className={clsx({
                      [classes.iconOpen]: open,
                      [classes.iconClose]: !open,
                      [classes.iconSelected]: window.location.pathname === '/gantts' && !open,
                    })} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('layout.tabs.ganttCharts')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* DOCUMENTS */}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tabs.documents') : ''} arrow>
                <ListItem
                  button
                  component={Link}
                  to="/documents"
                  className={window.location.pathname === '/documents' && open && classes.listItemSelected}
                >
                  <ListItemIcon>
                    <DescriptionIcon className={clsx({
                      [classes.iconOpen]: open,
                      [classes.iconClose]: !open,
                      [classes.iconSelected]: window.location.pathname === '/wallet' && !open,
                    })}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('layout.tabs.documents')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
              {/* TOKENS */}
              {account.user.role === CLIENT_EMPLOYEE ? null : (
                <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Tokens' : ''} arrow>
                  <ListItem
                    button
                    component={Link}
                    to="/wallet"
                    className={window.location.pathname === '/wallet' && open && classes.listItemSelected}
                  >
                    <ListItemIcon>
                      <MonetizationOnIcon className={clsx({
                        [classes.iconOpen]: open,
                        [classes.iconClose]: !open,
                        [classes.iconSelected]: window.location.pathname === '/wallet' && !open,
                      })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Tokens'
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    />
                  </ListItem>
                </DrawerTooltip>
              )}
              {account.user.role === SUPER_ADMIN && (
                <>
                  <Divider className={clsx({
                    [classes.dividerOpen]: open,
                    [classes.dividerClose]: !open,
                  })}
                  />
                  <p className={clsx(classes.tabTitle, {
                    [classes.tabTitleOpen]: open,
                    [classes.tabTitleClose]: !open,
                  })}
                  >{'Menú Admin'}</p>
                  {/* ADMINISTRATION */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.administration') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/administration"
                      className={window.location.pathname === '/administration' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <ApartmentIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/administration' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.administration')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* USERS */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.users') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/users"
                      className={window.location.pathname === '/users' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <SupervisorAccountIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/users' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.users')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* PRODUCTS */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.products') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/products"
                      className={(window.location.pathname === '/products' || window.location.pathname === '/upload-products') && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <CategoryIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: (window.location.pathname === '/products' || window.location.pathname === '/upload-products') && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.products')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* PRODUCTS SUBMENU */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Ver todos' : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/products"
                      className={clsx(classes.listSubItem, window.location.pathname === '/products' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/products' || window.location.pathname === '/upload-products'),
                      })}
                    >
                      <ListItemIcon>
                        <VisibilityIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/products' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.viewAll')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* PRODUCTS SUBMENU */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Cargar archivo' : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/upload-products"
                      className={clsx(classes.listSubItem, window.location.pathname === '/upload-products' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/products' || window.location.pathname === '/upload-products'),
                      })}
                    >
                      <ListItemIcon>
                        <SaveIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/upload-products' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.import')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* LOCATIONS */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.locations') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/locations"
                      className={(window.location.pathname === '/locations' || window.location.pathname === '/upload-locations') && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <StoreIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: (window.location.pathname === '/locations' || window.location.pathname === '/upload-locations') && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.locations')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* LOCATIONS SUBMENU */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'Ver todos' : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/locations"
                      className={clsx(classes.listSubItem, window.location.pathname === '/locations' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/locations' || window.location.pathname === '/upload-locations' || window.location.pathname === '/upload-product-locations' || window.location.pathname === '/upload-stock-and-sales-information'),
                      })}
                    >
                      <ListItemIcon>
                        <VisibilityIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/locations' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.viewAll')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* LOCATIONS SUBMENU */}
                  {/* <DrawerTooltip TransitionComponent={Zoom} placement='right'
                  title={!open ? 'Cargar archivo' : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/upload-locations"
                      className={clsx(classes.listSubItem, window.location.pathname ===
                      '/upload-locations' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/locations'
                        || window.location.pathname === '/upload-locations'
                        || window.location.pathname === '/upload-product-locations'
                        || window.location.pathname === '/upload-stock-and-sales-information'),
                      })}
                    >
                      <ListItemIcon>
                        <SaveIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname
                          === '/upload-locations' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.import')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip> */}
                  {/* LOCATIONS SUBMENU */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.productsInformation') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/upload-product-locations"
                      className={clsx(classes.listSubItem, window.location.pathname === '/upload-product-locations' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/locations' || window.location.pathname === '/upload-locations' || window.location.pathname === '/upload-product-locations' || window.location.pathname === '/upload-stock-and-sales-information'),
                      })}
                    >
                      <ListItemIcon>
                        <ProductInfoIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/upload-product-locations' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.productsInformation')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* LOCATIONS SUBMENU */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.stockAndSales') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/upload-stock-and-sales-information"
                      className={clsx(classes.listSubItem, window.location.pathname === '/upload-stock-and-sales-information' && open && classes.listSubItemSelected, {
                        [classes.hide]: !(window.location.pathname === '/locations' || window.location.pathname === '/upload-locations' || window.location.pathname === '/upload-product-locations' || window.location.pathname === '/upload-stock-and-sales-information'),
                      })}
                    >
                      <ListItemIcon>
                        <ProductInfoIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/upload-stock-and-sales-information' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.subTabs.stockAndSales')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* CATEGORIES */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.categories') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/categories"
                      className={window.location.pathname === '/categories' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <CategoryIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/categories' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.categories')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* GROUPS */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.groups') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/groups"
                      className={window.location.pathname === '/groups' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <GroupsIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/groups' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.groups')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* TASKS */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tooltips.tasks') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/tasks"
                      className={(window.location.pathname === '/tasks') && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <BallotIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: (window.location.pathname === '/tasks') && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('layout.tabs.tasks')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* QA */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? 'QA' : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/qa"
                      className={window.location.pathname === '/qa' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <ApartmentIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/qa' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={'QA'}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                  {/* TELEAUDIT */}
                  <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('teleaudit.sideBar') : ''} arrow>
                    <ListItem
                      button
                      component={Link}
                      to="/teleaudit"
                      className={window.location.pathname === '/teleaudit' && open && classes.listItemSelected}
                    >
                      <ListItemIcon>
                        <ApartmentIcon className={clsx({
                          [classes.iconOpen]: open,
                          [classes.iconClose]: !open,
                          [classes.iconSelected]: window.location.pathname === '/teleaudit' && !open,
                        })}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('teleaudit.sideBar')}
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      />
                    </ListItem>
                  </DrawerTooltip>
                </>
              )}
            </List>
          </div>
          <div className={classes.bottomContainer}>
            <Divider className={clsx({
              [classes.dividerOpen]: open,
              [classes.dividerClose]: !open,
            })}
            />
            <List>
              <p className={clsx(classes.tabTitle, {
                [classes.tabTitleOpen]: open,
                [classes.tabTitleClose]: !open,
              })}>{t('layout.tabsTitle.others')}</p>
              {account.user.role === SUPER_ADMIN && (
                <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tabs.settings') : ''} arrow>
                  <ListItem
                    button
                    component={Link}
                    to="/settings"
                    disabled={account.user.role !== SUPER_ADMIN}
                    className={window.location.pathname === '/settings' && open && classes.listItemSelected}
                  >
                    <ListItemIcon>
                      <SettingsIcon className={clsx({
                        [classes.iconOpen]: open,
                        [classes.iconClose]: !open,
                        [classes.iconSelected]: window.location.pathname === '/settings' && !open,
                      })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('layout.tabs.settings')}
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    />
                  </ListItem>

                </DrawerTooltip>
              )}
              <DrawerTooltip TransitionComponent={Zoom} placement='right' title={!open ? t('layout.tabs.logout') : ''} arrow>
                <ListItem button onClick={logout}>
                  <ListItemIcon><ExitToAppIcon className={classes.redIcon} /></ListItemIcon>
                  <ListItemText
                    primary={t('layout.tabs.logout')}
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  />
                </ListItem>
              </DrawerTooltip>
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
