import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, Button as MuiButton, makeStyles } from '@material-ui/core';

import Snackbar from '../Snackbar';

const useStyles = makeStyles((theme) => ({
  uploadFile: {
    display: 'flex',
    height: '38.5px',
    width: '130px',
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonsActions: {
    display: 'flex',
    '& button': {
      borderRadius: '4px',
      width: '150px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.blue,
        color: theme.palette.primary.white,
      },
    },
  },
  clearButton: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.secondary.blue,
    border: `1px solid ${theme.palette.secondary.blue}`,
    borderRadius: '4px',
    marginRight: '15px',
  },
  saveChangesButton: {
    marginRight: '15px',
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
  },
  headerContainer: {
    marginRight: '15px',
    '& button': {
      color: theme.palette.secondary.blue,
      border: `1px solid ${theme.palette.secondary.blue}`,
    },
  },
  disabled: {
    backgroundColor: theme.palette.secondary.gray,
    cursor: 'default',
  },
  messageError: {
    color: theme.palette.secondary.red,
  },
}));

function Header({
  setUploadDifference,
  uploadDifference,
  updateFunction,
  uploadFunction,
  templateColumns,
  downloadTemplate,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const company = useSelector((state) => state.company);

  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [loading, setLoading] = useState(false);

  const onFileChange = (event) => {
    setUploadDifference(null);
    setSelectedFile(event.target.files[0]);
  };

  const onSaveChanges = async () => {
    setLoading(true);
    setMessage('');

    // Send file object
    const updateResponse = await updateFunction(selectedFile);

    if (updateResponse.status === 200) {
      setUploadDifference(null);
      setSelectedFile(null);
      setSeverity('success');
      setMessage(t('uploadFile.success'));
    } else {
      setSeverity('error');
      setMessage(updateResponse.data.errorMessage);
    }
    setLoading(false);
  };

  const onFileUpload = async () => {
    setLoading(true);
    setMessage('');

    // Send file object
    const uploadResponse = await uploadFunction(selectedFile);

    if (uploadResponse.status === 200) {
      setUploadDifference(uploadResponse.data.data.differences);
    } else {
      setSeverity('error');
      setMessage(uploadResponse.data.errorMessage);
    }
    setLoading(false);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent='flex-end'
        style={{ alignItems: 'end' }}
      >
        {!company || !company.companyId
          ? <span className={classes.messageError}>
            *Seleccionar una empresa para poder cargar un archivo con información de producto.
          </span>
          : null}
        {(!selectedFile || !uploadDifference) && (
          <Grid item className={classes.headerContainer}>
            <label htmlFor="upload-file">
              <input
                style={{ display: 'none' }}
                id="upload-file"
                name="upload-file"
                type="file"
                onChange={onFileChange}
                disabled={!company || !company.companyId}
              />
              <div className={`${!company || !company.companyId ? classes.disabled : ''} ${classes.uploadFile}`}>  {t('uploadFile.upload')}</div>
            </label>
          </Grid>
        )}
        {selectedFile && (
          <>
            <Grid item className={classes.headerContainer}>
              <MuiButton disabled={loading} onClick={onFileUpload}>
                {t('uploadFile.import')} {selectedFile.name}
              </MuiButton>
            </Grid>

            <Grid item>
              <div className={classes.buttonsActions}>
                {selectedFile && uploadDifference && uploadDifference.length > 0 && (
                  <MuiButton disabled={loading} onClick={onSaveChanges}
                    className={classes.saveChangesButton}>
                    {t('uploadFile.saveChanges')}
                  </MuiButton>
                )}
                <MuiButton
                  className={classes.clearButton}
                  disabled={loading}
                  onClick={() => {
                    setSelectedFile(null);
                    setUploadDifference(null);
                  }}
                >
                  {t('uploadFile.clear')}
                </MuiButton>
              </div>
            </Grid>
          </>
        )}
        {templateColumns ? (
          <Grid item className={classes.headerContainer}>
            <MuiButton disabled={loading} onClick={downloadTemplate}>
              {t('uploadFile.downloadTemplate')}
            </MuiButton>
          </Grid>
        ) : null}
      </Grid>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default Header;
