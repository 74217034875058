import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.02)',
  },
  welcomeContainer: {
    '& img': {
      width: '95px',
      margin: '0 auto 30px',
    },
    flexDirection: 'column',
    padding: '50px 140px',
    height: '280px',
  },
  welcomeTitle: {
    '& .MuiInput-root': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: theme.palette.primary.mainBlue,
    },
  },
  welcomeMessage: {
    '& .MuiInput-root': {
      color: theme.palette.primary.gray,
      fontSize: '12px',
      lineHeight: '1.7',
    },
  },
  selectPageContainer: {
    display: 'block',
    padding: '30px 107px',
    '& hr': {
      margin: '12px 0px',
    },
    '& hr:nth-child(2)': {
      marginBottom: '12px',
      marginTop: '0px',
    },
  },
  subTitle: {
    ...theme.typography.subtitle1,
    marginBottom: '15px',
    marginTop: '0px',
  },
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    '& p': {
      color: theme.palette.primary.middleGray,
      fontSize: '14px',
    },
    '& svg': {
      marginRight: '11px',
    },
    '& rect': {
      borderRadius: '8px',
      backgroundColor: 'red',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      margin: '11px 0px',
    },
    '& button': {
      justifyContent: 'flex-start',
    },
  },
  container: {
    display: 'flex',
    width: '60%',
  },
  identifier: {
    marginTop: '97px',
    marginLeft: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.mainBlue,
  },
  coloredDiv: {
    minHeight: '280px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: theme.palette.secondary.blue,
    opacity: 0.1,
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '90px 35px',
    paddingLeft: '7px',
    maxWidth: '500px',
    '& button': {
      justifyContent: 'flex-start',
      fontSize: '10px',
      padding: '0px',
      marginTop: '8px',
      width: '170px',
      color: theme.palette.secondary.blue,
    },
    '& div': {
      display: 'flex',
      alignItems: 'left',
    },
  },
  simpleSelection: {
    maxWidth: '270px',
  },
  question: {
    maxHeight: '83px',
    maxWidth: '280px',
    '& .MuiInput-root': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: theme.palette.primary.mainBlue,
      paddingBottom: '5px',
    },
  },
  description: {
    margin: '8px 0px 20px',
    '& .MuiInput-root': {
      fontSize: '14px',
      padding: '0px',
      color: theme.palette.primary.gray,
    },
  },
  descriptionKpi: {
    width: '95%',
    '& .MuiInput-root': {
      fontSize: '14px',
      padding: '0px',
      color: theme.palette.primary.gray,
    },
  },
  checkbox: {
    color: theme.palette.secondary.gray,
    height: '30px',
    '& .MuiIconButton-root': {
      color: theme.palette.secondary.gray,
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& svg': {
      width: '14px',
      height: '14px',
    },
    '& span': {
      fontSize: '14px',
    },
  },
  option: {
    '& input': {
      fontSize: '14px',
      paddingBottom: '2px',
    },
    '& .Mui-focused': {
      borderBottom: `1px solid ${theme.palette.secondary.blue}`,
    },
  },
  centeredContainer: {
    justifyContent: 'center',
    '& p': {
      marginLeft: '0px',
    },
  },
  textfield: {
    borderBottom: `1px solid ${theme.palette.secondary.blue}`,
  },
  satisfactionContainer: {
    padding: '90px 0px',
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& label': {
      margin: '0px',
    },
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontSize: '10px',
      fontWeight: '100',
      color: theme.palette.secondary.blue,
      marginTop: '60px',
    },
    '& p:first-child': {
      marginLeft: '10px',
    },
  },
  hide: {
    display: 'none',
  },
  deleteButton: {
    alignSelf: 'flex-start',
    color: theme.palette.secondary.lightGray,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  deleteButtonSelectPage: {
    float: 'right',
    color: theme.palette.secondary.lightGray,
  },
  list: {
    maxHeight: 320,
  },
  emojiGrid: {
    marginBottom: '60px',
  },
  formWrapper: {
    width: '30vw',
    padding: '56px',
    paddingTop: '10px',
    paddingBottom: '20px',
    '& .MuiFormLabel-root': {
      color: '#808080',
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  numberContainer: {
    height: '50px',
    '& input': {
      height: '100%',
      fontSize: '25px',
      color: theme.palette.primary.gray,
    },
  },
  surveyForm: {
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    columnGap: '5%',
    padding: '30px 60px 55px',
    width: '70vw',
    borderRadius: '10px',
    '& label': {
      color: theme.palette.primary.darkGray,
      fontSize: '13px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.lightGray}`,
      borderRadius: '5px',
      marginTop: '27px',
      padding: '5px',
      paddingLeft: '10px',
    },
    '& .MuiInput-root.Mui-focused': {
      border: `1px solid ${theme.palette.secondary.blue}`,
      borderRadius: '5px',
    },
    '& .MuiInput-input': {
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '7px',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.blue,
    },
  },
  modalForm: {
    minWidth: '700px',
    minHeight: '350px',
    borderRadius: '12px',
  },
  kpiList: {
    height: '130px',
    borderRadius: '5px',
    overflowY: 'scroll',
  },
  firstStepText: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  buttonNext: {
    fontWeight: 'bold',
    borderRadius: '10px',
    width: '80%',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.blue,
  },
  buttonBack: {
    marginRight: '15px',
    width: '80%',
    color: theme.palette.primary.middleGray,
    backgroundColor: theme.palette.primary.white,
    border: `0.8px solid ${theme.palette.secondary.gray}`,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightGray,
    },
  },
  dialogWrapper: {
    width: '40vw',
    padding: '56px',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  dialogText: {
    padding: '30px',
  },
  memoryContainer: {
    width: '160px',
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '30px',
    },
    '& .MuiInputBase-input': {
      fontSize: '12px',
    },
    '& .MuiSelect-select': {
      maxWidth: '51px !important',
      fontSize: '12px',
    },
  },
  qualityContainer: {
    width: '160px',
    '& .MuiSelect-select': {
      fontSize: '12px',
    },
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  switch: {
    '& .MuiTypography-body1': {
      fontSize: '12px !important',
    },
    '& .MuiFormControlLabel-labelPlacementStart, .MuiFormControlLabel-root': {
      marginLeft: '0',
      marginRight: '5px',
    },
  },
}));

export default useStyles;
