import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Switch,
  Box,
} from '@material-ui/core';
// icons
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
// components
import TableToolbar from './TableToolbar';
import Modal from '../../components/Modal';
// import LocationForm from './locationForm';
import LocationInfo from './locationInfo';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';

import useStyles from './styles';
import { getLocationsPerPage, deleteLocation, putNewDistributionCenter } from '../../requests/api/location';
// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';

function Locations() {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [locationsQuantity, setLocationsQuantity] = useState(0);

  // auxiliar states
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // Create Modal
  const [openCreateModal, setOpenCreateModal] = useState(false);

  // Edit Location states
  const [locationId, setLocationId] = useState(null);
  // const [openEditModal, setOpenEditModal] = useState(false);

  const [locationRow, setlocationRow] = useState(null);
  const [openChangeDistributionCenterModal, setOpenChangeDistributionCenterModal] = useState(false);

  // Watch
  const [openWatchModal, setOpenWatchModal] = useState(false);

  const account = useSelector((state) => state.account);
  const company = useSelector((state) => state.company);

  const tableHeaders = [
    {
      id: 'imageUrl',
      label: t('locations.columnNames.imageUrl'),
    },
    {
      id: 'name',
      label: t('locations.columnNames.name'),
    },
    {
      id: 'internal_code',
      label: t('locations.columnNames.internal_code'),
    },
    // {
    //   id: 'company',
    //   label: t('locations.columnNames.company'),
    // },
    {
      id: 'address',
      label: t('locations.columnNames.address'),
    },
    {
      id: 'city',
      label: t('locations.columnNames.city'),
    },
    {
      id: 'chain',
      label: t('locations.columnNames.chain'),
    },
    {
      id: 'deleted',
      label: t('locations.columnNames.deleted'),
    },
    {
      id: 'isDistributionCenter',
      label: t('locations.columnNames.isDistributionCenter'),
    },
  ];

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const noDeleted = filter === '' ? '' : !(filter === 'true');
    const response = await getLocationsPerPage(
      pageOfTable, limit, searchValue, columnName, noDeleted,
    );
    if (response.success) {
      setRowsToShow(response.data.data.locations);
      setLocationsQuantity(response.data.data.totalLocations);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }

    if (Array.isArray(columnName)) setSearchText(searchValue);
  };

  const setFilterOptions = (newValue, tableColumn) => {
    if (Array.isArray(tableColumn)) setSearchText(newValue);

    setColumn(tableColumn);
    setFilter(newValue);

    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  // Select all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowsToShow.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Select this row
  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setLocationId(id);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const handleEdit = async (event) => {
  //   const { id } = event.currentTarget;
  //   setOpenEditModal(true);
  //   setLocationId(id);
  // };

  const handleWatch = async (event) => {
    const { id } = event.currentTarget;
    setOpenWatchModal(true);
    setLocationId(id);
  };

  const handleDelete = async () => {
    setLoading(true);
    for (let i = 0; i < selected.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await deleteLocation(selected[i]);
    }
    setSelected([]);
    requestSearch(page, rowsPerPage, filter, column);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    requestSearch(page, rowsPerPage, filter, column);
  }, [column, filter, page, rowsPerPage, account.user.role, update, company]);

  const handleChangeDistributionCenter = (row) => {
    setlocationRow(row);
    setOpenChangeDistributionCenterModal(true);
  };

  const handleSaveNewDistributionCenter = async () => {
    const response = await putNewDistributionCenter(locationRow.id);
    if (response.success) {
      const updatedRows = rowsToShow.map((row) => ({
        ...row,
        isDistributionCenter: row.id === locationRow.id,
      }));

      setRowsToShow(updatedRows);
      setlocationRow(null);
      setOpenChangeDistributionCenterModal(false);
    }
  };

  const content = () => {
    if (!locationsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.locations}>
            <h2 className={styles.noContent}>{t('locations.noContent')}</h2>
            <Button
              id="submit-ticket-btn"
              onClick={() => setOpenCreateModal(true)}
              text={t('locations.noContentButton')}
              height="40px"
              width="160px"
              backgroundColor='#2C9DC9 !important'
              color='white !important'
              borderRadius='10px'
            />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell padding="none">
                  {
                    row.imageUrl
                      ? <img
                      className={styles.logoUrl}
                      src={row.imageUrl}
                      alt="N/A"
                    />
                      : <div style={{ width: '45px', height: '45px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
                  }
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.internalCode}</TableCell>
                {/* <TableCell align="left">{row.Company ? row.Company.name : '-'}</TableCell> */}
                <TableCell align="left">{row.Address ? row.Address.address : '-'}</TableCell>
                <TableCell align="left">{row.Address ? row.Address.city : '-'}</TableCell>
                <TableCell align="left">{row.chain}</TableCell>
                <TableCell align="left">
                  {row.deleted ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="left">
                  <FormControlLabel
                    className={styles.switch}
                    control={<Switch checked={row.isDistributionCenter} disabled={row.isDistributionCenter} onChange={() => handleChangeDistributionCenter(row)} color="primary" />}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Typography variant='h1' className={styles.title}>{t('locations.title')}</Typography>
        {/* {openEditModal ? (
          <Modal title={t('locations.locationForm.editModalTitle')}
          hideButton={true} defaultOpen={openEditModal} setOpenEditModal={setOpenEditModal}>
            <LocationForm locationId={locationId} />
          </Modal>
        ) : null} */}
        {openWatchModal ? (
          <Modal title={t('locations.locationInfo.watchModalTitle')} hideButton={true} defaultOpen={openWatchModal} setOpenEditModal={setOpenWatchModal}>
            <LocationInfo locationId={locationId} />
          </Modal>
        ) : null}
        {openChangeDistributionCenterModal ? (
          <Modal title={t('locations.distributionCenterModalTitle')} hideButton={true} defaultOpen={openChangeDistributionCenterModal} setOpenEditModal={setOpenChangeDistributionCenterModal}>
            <Box className={styles.modalBox}>
              <Typography>
                {t('locations.distributionCenterModalParagraph')}
              </Typography>
              <Box className={styles.buttonsBox}>
                <Button
                  height="35px"
                  width="130px"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                  onClick={handleSaveNewDistributionCenter}
                >{t('locations.confirm')}</Button>
                <Button
                  height="35px"
                  width="130px"
                  color="#2C9DC9"
                  borderRadius="10px"
                  onClick={() => {
                    setlocationRow(null);
                    setOpenChangeDistributionCenterModal(false);
                  }}
                >{t('locations.cancel')}</Button>
              </Box>
            </Box>
          </Modal>
        ) : null}
      </div>
      <Paper className={styles.locationTable}>
        <>
          <TableToolbar
            numSelected={selected.length}
            open={open}
            searchText={searchText}
            setOpen={setOpen}
            setFilterOptions={setFilterOptions}
            openCreateModal={openCreateModal}
            setOpenCreateModal={setOpenCreateModal}
            // handleEdit={handleEdit}
            handleWatch={handleWatch}
            handleDelete={handleDelete}
            locationId={locationId && locationId}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeader} padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < rowsToShow.length
                      }
                      checked={rowsToShow.length > 0 && selected.length === rowsToShow.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader.id}
                      className={styles.tableHeader}
                      align={tableHeader.id === 'active' ? 'center' : 'left'}
                      padding="normal"
                    >
                      {tableHeader.id === 'name' && selected.length
                        ? `Selected ${selected.length} locations`
                        : tableHeader.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} className={styles.progress}>
                      <CircularProgress />
                      <h4>{t('locations.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={locationsQuantity}
          />
        </>
      </Paper>
    </div>
  );
}

export default Locations;
