import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const SurveyQuestionBox = ({ questions, reaudit }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { taskResponseId } = useParams();

  const questionIsPending = (isPending) => {
    switch (isPending) {
      case false:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagCompleted}`}><b>{t('qa.status.completed')}</b></p>
        );
      case true:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagPending}`}><b>{t('qa.status.pending')}</b></p>
        );
      default:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagPending}`}><b>{t('qa.status.pending')}</b></p>
        );
    }
  };

  return (
    <div className={styles.root}>
      <Typography variant='h6'>
        {reaudit
          ? t('teleaudit.surveyQuestionBox.questionsReaudit')
          : t('teleaudit.surveyQuestionBox.questionsTeleaudit')}
      </Typography>
      <Table>
        {questions.length > 0
          ? questions.map((questionResponse) => (
            <TableRow key={questionResponse.id}>
              <TableCell align="left" width='25%'><b>{questionResponse.Question.title}</b></TableCell>
              <TableCell align="center" width='20%'>
                <b>
                  {questionResponse.questionResponseImageQuantity}
                  {questionResponse.questionResponseImageQuantity === 1
                    ? t('teleaudit.surveyQuestionBox.body.image')
                    : t('teleaudit.surveyQuestionBox.body.images')}
                </b>
              </TableCell>
              <TableCell align="center" width='30%'>
                {reaudit
                  ? (
                    <p align="left" className={`${styles.tag} ${styles.tagReaudit}`}>
                      <b>
                        Reaudit
                      </b>
                    </p>
                  )
                  : questionIsPending(questionResponse.teleauditStatus === 'pending')
                }
              </TableCell>
              <TableCell align="right" width='25%'>
                {reaudit
                  ? <Button
                    component={Link}
                    to={`/teleaudit/${taskResponseId}/${questionResponse.id}`}
                    variant="contained"
                    className={styles.enableButton}
                    align='center'
                  >
                    {t('teleaudit.surveyQuestionBox.body.toTeleaudit')}
                  </Button>
                  : <Button
                    component={Link}
                    to={`/teleaudit/${taskResponseId}/${questionResponse.id}`}
                    variant="contained"
                    className={clsx({ [styles.enableButton]: true })}
                    align='center'
                  >
                    {t('teleaudit.surveyQuestionBox.body.toTeleaudit')}
                  </Button>
                }
              </TableCell>
            </TableRow>
          ))
          : <TableRow>
            <TableCell colSpan={5} align="center">
              <Typography variant="h6">{t('teleaudit.surveyQuestionBox.body.noQuestions')}</Typography>
            </TableCell>
          </TableRow>
        }
      </Table>
    </div>
  );
};

export default SurveyQuestionBox;
