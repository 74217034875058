/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import {
  Button, Divider, FormControlLabel, Switch, Grid, TextField,
} from '@material-ui/core';

import {
  DragIndicator, Edit, ExpandLess, ExpandMore,
  VisibilityOutlined,
} from '@material-ui/icons';

import {
  DragDropContext, Droppable, Draggable,
} from 'react-beautiful-dnd';

import useStyles from './styles';

// const
import {
  DROP_DOWN_MENU, END_PAGE, LONG_ANSWER, SIMPLE_SELECTION,
  SATISFACTION, SHORT_ANSWER, MULTIPLE_SELECTION, WELCOME_PAGE,
  YES_NO_QUESTION, NUMERIC_ANSWER, DATE, NPS_QUESTION, CONTENT,
  MULTIPLE_IMAGE_QUESTION, SIGNATURE_QUESTION, BARCODE_QUESTION,
  DECIMAL_ANSWER, PRICE, VIDEO,
} from '../../../utils/const/pageTypes';

import { EDIT_MODE } from '../../../utils/const/taskModes';

import { IMAGE, RESUME_PAGE } from '../../../utils/const/questions';

// components
import SelectPage from '../../../components/Pages/SelectPage';
import WelcomePage from '../../../components/Pages/WelcomePage';
import ResumePage from '../../../components/Pages/ResumePage';
import SimpleSelection from '../../../components/Pages/SimpleSelection';
import MultipleSelection from '../../../components/Pages/MultipleSelection';
import ShortAnswer from '../../../components/Pages/ShortAnswer';
import DropDownMenu from '../../../components/Pages/DropDownMenu';
import LongAnswer from '../../../components/Pages/LongAnswer';
import Satisfaction from '../../../components/Pages/Satisfaction';
import ImagesAnswer from '../../../components/Pages/ImageAnswer';
import NumericAnswer from '../../../components/Pages/NumericAnswer';
import Snackbar from '../../../components/Snackbar';
import DateAnswer from '../../../components/Pages/DateAnswer';
import QuestionLogic from '../../QuestionLogic';
import YesNoQuestion from '../../../components/Pages/YesNoQuestion';
import NpsQuestion from '../../../components/Pages/NpsQuestion';
import MultipleImageQuestion from '../../../components/Pages/MultipleImageQuestion';
import SignatureQuestion from '../../../components/Pages/SignatureQuestion';
import BarcodeQuestion from '../../../components/Pages/BarcodeQuestion';
import SettingsModal from '../settingsModal/settingModal';
import questionValidator from '../../../utils/questionsValidator';
import DecimalAnswer from '../../../components/Pages/DecimalAnswer';
import Price from '../../../components/Pages/Price';

import { PRODUCT } from '../../../utils/const/formTypes';

// requests
import { postNewSurvey } from '../../../requests/api/survey';
import Video from '../../../components/Pages/Video';

function CreateTask({
  formType, project, inputValues, vertical, setEditView, setModalOpen,
  setInputValues, valuesProp, setValuesProp, switchStock, setSwitchStock,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // snackbar status
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [values, setValues] = useState({
    title: inputValues.title,
    formType,
    project,
    questions: [],
  });

  const [verticalForm, setVerticalForm] = useState(vertical);
  const [openConfigs, setOpenConfigs] = useState(false);
  const [georeference, setGeoreference] = useState(true);
  const [viewDetail, setViewDetail] = useState(null);
  const generalView = false;
  // const [generalView, setGeneralView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [pages, setPages] = useState([
    {
      identifier: 0,
      hiddenIdentifier: 0,
      type: null,
      name: '',
      required: false,
    },
  ]);

  // const [tab, setTab] = useState(0);
  const [selectedPage, setSelectedPage] = useState(null);
  const [openConnections, setOpenConnections] = useState(false);
  const [revisionPolicy, setRevisionPolicy] = useState({
    name: t('task.taskEdition.revisionPolicy.placeholder'),
    value: '',
  });

  // Change the tool tab
  // const handleChangeTab = (event, newTab) => {
  //   setTab(newTab);
  // };

  // Select page
  const handleSelectPage = (event) => {
    const [type, identifier] = event.currentTarget.id.split('-');

    if (type === 'null') return;

    setSelectedPage(identifier);
  };

  // Adds a new page
  const handleAddSection = () => {
    setPages([
      ...pages,
      {
        identifier: `${pages.length + 1}`,
        hiddenIdentifier: `${pages.length + 1}`,
        type: null,
      },
    ]);
  };

  // Handles the status that indicates whether the question is required or not
  const handleSwitch = (event) => {
    setUpdate((prevState) => !prevState);
    const { name, checked } = event.target;

    pages[name].required = checked;
  };

  const handleSwitchStock = (event) => {
    const { checked } = event.target;

    if (checked) {
      setValuesProp({
        ...valuesProp,
        isSwitchStock: checked,
        productInformation: false,
      });
    } else {
      setValuesProp({
        ...valuesProp,
        isSwitchStock: checked,
      });
    }
  };

  const handleSwitchProductInformation = (event) => {
    const { checked } = event.target;

    if (checked) {
      setValuesProp({
        isSwitchStock: false,
        productInformation: checked,
      });
    } else {
      setValuesProp({
        ...valuesProp,
        productInformation: checked,
      });
    }
  };

  // Relocates changed pages in the array
  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const copiedItems = [...pages];
    for (let i = 0; i < copiedItems.length; i += 1) {
      copiedItems[i].hiddenIdentifier = `${(i + 1)}`;
    }
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    for (let j = 0; j < copiedItems.length; j += 1) {
      // Updates identifier question, ignore informative pages
      copiedItems[j].identifier = `${(j + 1)}`;

      const questionLogicCopiedItem = copiedItems[j].questionLogic;
      if (questionLogicCopiedItem) {
        for (let i = 0; i < questionLogicCopiedItem.length; i += 1) {
          if (questionLogicCopiedItem[i].questionChoiceIdentifier) {
            const newIdToNextQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].nextQuestionIdentifier,
            );
            const idQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].questionIdentifier,
            );
            if (newIdToNextQuestion && idQuestion) {
              // eslint-disable-next-line max-len
              copiedItems[j].questionLogic[i].nextQuestionIdentifier = newIdToNextQuestion.identifier;
              copiedItems[j].questionLogic[i].questionIdentifier = idQuestion.identifier;
            }
          } else {
            copiedItems[j].questionLogic[i].questionIdentifier = `${(j + 1)}`;
            copiedItems[j].questionLogic[i].nextQuestionIdentifier = `${(j + 2)}`;
          }
        }
      }

      if (copiedItems[j].questionChoices) {
        // Ensure questionChoices are sorted by identifier
        copiedItems[j].questionChoices = copiedItems[j].questionChoices.sort(
          (a, b) => a.identifier - b.identifier,
        );
      }
    }

    const switchStockIndex = copiedItems.findIndex((ci) => ci.isSwitchStock);

    if (switchStockIndex !== -1) {
      setSwitchStock(copiedItems[switchStockIndex].identifier, 10);
    }
    copiedItems.forEach((copiedItem) => {
      delete copiedItem.hiddenIdentifier;
    });
    setPages([
      ...copiedItems,
    ]);
  };

  const handleSaveSurvey = async () => {
    let revisionPolicyValidator = revisionPolicy.value;
    if (!revisionPolicyValidator) {
      setSeverity('error');
      setMessage(t('task.saveErrors.noRequiresRevision'));
      return;
    }

    if (valuesProp.isSwitchStock && switchStock == null) {
      setSeverity('error');
      setMessage(t('task.saveErrors.switchStockRequiredQuestion'));
      return;
    }

    const questionTypesFixed = [];

    let position = 0;
    let validator = 0;

    pages.forEach((page) => {
      if (validator !== 0) return;
      position += 1;

      if (!page.type) return;

      page.position = position;
      validator = questionValidator(page, formType.name);
      if (validator !== 0) {
        setSeverity('error');
        switch (validator) {
          case 1:
            setMessage(`${t('task.saveErrors.title')} ${position}`);
            break;
          case 2:
            setMessage(`${t('task.saveErrors.binaryKpis')} ${position}`);
            break;
          case 4:
            setMessage(`${t('task.saveErrors.correctQuestionChoices')} ${position}`);
            break;
          default:
            setMessage(`${t('task.saveErrors.questionChoices')} ${position}`);
            break;
        }
        return;
      }
      const nextQuestionIdentifiers = page.questionLogic.map(
        (questionLogic) => questionLogic.nextQuestionIdentifier,
      );

      const index = nextQuestionIdentifiers.findIndex((nqi) => nqi === 'end' || nqi === '');

      if (index !== -1) {
        page.questionLogic[index].nextQuestionIdentifier = pages.length;
      }

      if (revisionPolicyValidator !== 'yes') {
        if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(page.type)) {
          if (page.requiresTeleaudit) {
            revisionPolicyValidator = 'yes';
          }
        }
      }
      page.isSwitchStock = page.identifier === switchStock;
      page.minMemory = page.memoryQuantity && page.memoryUnit ? `${page.memoryQuantity} ${page.memoryUnit}` : null;
      delete page.memoryQuantity;
      delete page.memoryUnit;
      delete page.hiddenIdentifier;
      questionTypesFixed.push(page);
    });
    if (validator === 0) {
      const createSurveyResponse = await postNewSurvey({
        title: inputValues.title,
        questions: questionTypesFixed,
        projectId: project.value,
        type: formType.name,
        requiredGeoreference: georeference,
        revisionPolicy: revisionPolicyValidator,
        vertical: formType.name === PRODUCT ? true : verticalForm,
        switchStock: valuesProp.isSwitchStock,
        productInformation: valuesProp.productInformation,
      });

      if (createSurveyResponse.success) {
        setSeverity('success');
        setMessage(t('task.messages.success.taskCreate'));
        setModalOpen(false);
        setEditView(false);
        setInputValues({
          title: '',
          type: { name: '', value: '' },
          project: { name: '', value: '' },
        });
        setVerticalForm(false);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.error.taskCreate'));
      }
    }
  };

  // const handleGeneralView = () => {
  //   setGeneralView((prevState) => !prevState);
  // };

  const handleViewQuestion = (event) => {
    const { id } = event.currentTarget;

    if (id === viewDetail) {
      setViewDetail(null);
      return;
    }

    setViewDetail(id);
  };

  useEffect(() => () => {
    setValues({
      ...values,
      questions: pages,
    });
    // eslint-disable-next-line
  }, [pages, openConnections, update]);

  const handleOpenConfigs = () => {
    setOpenConfigs(!openConfigs);
  };

  const handleDeleteQuestion = (event) => {
    const { id } = event.currentTarget;

    const copyPages = pages;
    const pageKeys = Object.keys(copyPages);

    const newPages = [];

    pageKeys.forEach((pageIdentifier) => {
      if (pageIdentifier < (id - 1)) newPages.push(copyPages[pageIdentifier]);
      if (pageIdentifier > (id - 1)) {
        copyPages[pageIdentifier].identifier = pageIdentifier;
        const questionLogic = copyPages[pageIdentifier].questionLogic.find(
          (ql) => ql.questionChoiceIdentifier === null,
        );
        if (questionLogic) {
          questionLogic.questionIdentifier = pageIdentifier;
          questionLogic.nextQuestionIdentifier = (Number.parseInt(pageIdentifier, 10) + 1)
            .toString();
        }
        newPages.push(copyPages[pageIdentifier]);
      }
    });

    setPages(newPages);
  };

  /**
   * Duplicates a question and adds it to the end of the pages array
   * @param {Event} event - The event object
   */
  const handleDuplicateQuestion = (event) => {
    const { id } = event.currentTarget;
    const question = pages.find((p) => `${p.identifier}` === `${id}`);

    if (question) {
      const newQuestion = {
        ...question,
        identifier: `${pages.length + 1}`,
        hiddenIdentifier: `${pages.length + 1}`,
        position: `${pages.length + 1}`,
        questionLogic: [],
      };

      delete newQuestion.databaseId;
      if (question.questionChoices) {
        for (let i = 0; i < question.questionChoices.length; i += 1) {
          delete newQuestion.questionChoices[i].id;
        }
      }

      setPages([...pages, newQuestion]);
    }
  };

  const handleCreateConnections = () => {
    let errors = '';
    pages.forEach((page) => {
      const identifier = page.identifier === 0 ? 1 : page.identifier;
      const validator = questionValidator(page, formType.name);
      if (validator !== 0) {
        setSeverity('error');
        switch (validator) {
          case 1:
            errors += `${t('task.saveErrors.title')} ${identifier}\n`;
            break;
          case 2:
            errors += `${t('task.saveErrors.binaryKpis')} ${identifier}\n`;
            break;
          case 4:
            errors += `${t('task.saveErrors.correctQuestionChoices')} ${identifier}\n`;
            break;
          case 5:
            errors += `${t('task.saveErrors.questionType')} ${identifier}\n`;
            break;
          default:
            errors += `${t('task.saveErrors.questionChoices')} ${identifier}\n`;
            break;
        }
      }
    });

    if (errors === '') {
      setOpenConnections(true);
    } else {
      setMessage(errors);
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <TextField
            value={inputValues.title}
            onChange={(e) => setInputValues({
              ...inputValues, title: e.target.value,
            })}
          />
          <p>
            {project.name}
          </p>
        </div>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleCreateConnections}
                disabled={pages.length < 2
                  || (pages.length === 2 && !pages[1].type)
                  || !pages[pages.length - 1].type}
              >
               {t('task.questionLogic.connections')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleOpenConfigs}
              >
                {t('task.taskEdition.settings')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleSaveSurvey}
                disabled={pages.length === 1 && !pages[0].type}
              >
                {t('task.taskEdition.manageButton')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={styles.surveyContent}>
        <div className={styles.editionArea}>
          {formType.value === 'product' ? (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    name='is_switch_stock'
                    className={styles.switch}
                    onChange={handleSwitchStock}
                    checked={valuesProp.isSwitchStock}
                    disabled={pages.findIndex((p) => p.type === YES_NO_QUESTION) === -1
                      || valuesProp.productInformation}
                  />
                }
                label={t('task.taskEdition.stockSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    name='product_information'
                    className={styles.switch}
                    onChange={handleSwitchProductInformation}
                    checked={valuesProp.productInformation}
                    disabled={valuesProp.isSwitchStock}
                  />
                }
                label={t('task.taskEdition.productInformationSwitch')}
              />
            </div>
          ) : null}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {pages.map((page, index) => (
                    <Draggable draggableId={`${index}`} index={index} key={index}>
                      {(prov) => (
                        <div
                          id={`${page.type}-${index}`}
                          onClick={handleSelectPage}
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                        >
                          <ExpandLess
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx(styles.pageInfo, {
                              [styles.selectedPageGeneralView]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                              [styles.generalViewPageTitle]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            <div>
                              {generalView && <DragIndicator />}
                              <p className={styles.pageTitle}>
                                {t('task.taskEdition.page')} {index + 1}
                                {page.type ? `: ${page.name}` : ''}
                              </p>
                            </div>
                            <div>
                              {page.type && generalView ? (
                                <>
                                  <Edit fontSize="small" />
                                  <Button
                                    id={index}
                                    onClick={handleViewQuestion}
                                  >
                                    <VisibilityOutlined className={styles.viewQuestion} />
                                  </Button>
                                </>
                              ) : null}
                              {page.type && !generalView
                                && ![
                                  WELCOME_PAGE, END_PAGE, CONTENT, RESUME_PAGE,
                                ].includes(page.type)
                                ? (
                                  <>
                                    <p className={styles.pageTitle}>{t('task.taskEdition.obligatory')}</p>
                                    <FormControlLabel
                                      control={
                                        <Switch name={`${index}`} className={styles.switch} onChange={handleSwitch} checked={page.required} />
                                      }
                                      label=""
                                    />
                                  </>
                                ) : null}
                            </div>
                          </div>
                          <ExpandMore
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx({
                              [styles.selectedPageStyle]:
                                selectedPage === `${index}`,
                              [styles.hideContent]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            {([WELCOME_PAGE, END_PAGE, CONTENT].includes(page.type))
                              && <WelcomePage
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            }
                            {page.type === RESUME_PAGE && (
                              <ResumePage
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SIMPLE_SELECTION && (
                              <SimpleSelection
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === MULTIPLE_SELECTION && (
                              <MultipleSelection
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SHORT_ANSWER && (
                              <ShortAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === LONG_ANSWER && (
                              <LongAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === DROP_DOWN_MENU && (
                              <DropDownMenu
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SATISFACTION && (
                              <Satisfaction
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                editMode={false}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === IMAGE && (
                              <ImagesAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === YES_NO_QUESTION && (
                              <YesNoQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                switchStock={switchStock}
                                setSwitchStock={setSwitchStock}
                                isSwitchStock={valuesProp.isSwitchStock}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === DATE && (
                              <DateAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === NUMERIC_ANSWER && (
                              <NumericAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === DECIMAL_ANSWER && (
                              <DecimalAnswer
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === NPS_QUESTION && (
                              <NpsQuestion
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === MULTIPLE_IMAGE_QUESTION && (
                              <MultipleImageQuestion
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SIGNATURE_QUESTION && (
                              <SignatureQuestion
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === BARCODE_QUESTION && (
                              <BarcodeQuestion
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === PRICE && (
                              <Price
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === VIDEO && (
                              <Video
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {!page.type && (
                              <SelectPage
                                setPages={setPages}
                                pages={pages}
                                formType={formType}
                                identifier={page.identifier}
                                setSwitchStock={setSwitchStock}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            className={styles.button}
            onClick={handleAddSection}
          >
            {t('task.taskEdition.addPageButton')}
          </Button>
        </div>
        {/* <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <StyledTabs
              value={tab}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <StyledTab label={t('task.taskEdition.tabOne')} />
              <StyledTab label={t('task.taskEdition.tabTwo')} />
            </StyledTabs>
            <div className={styles.toolsContent}>
              {tab === 1 ? (
                <div className={styles.connectionButton}>
                  <p>
                    <b>{t('task.questionLogic.content')}</b>
                    <br />
                    {t('task.questionLogic.connections')}
                  </p>
                  <IconButton
                    aria-label="connections"
                    onClick={handleCreateConnections}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              ) : null}
              {!selectedPage && tab !== 1 ? (
                <p>
                  <em>{t('task.taskEdition.toolsPlaceholder')}</em>
                </p>
              ) : null}
              <div className={styles.buttonsToolsBar}>
                <Button>
                  <VisibilityOutlined />
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleGeneralView}
                >
                  {generalView ? t('task.taskEdition.extendedViewButton')
                    : t('task.taskEdition.generalViewButton')}
                </Button>
              </div>
            </div>
          </Paper>
        </div> */}
      </div>
      {openConnections ? (
        <QuestionLogic
          open={openConnections}
          setOpen={setOpenConnections}
          inputValues={pages}
          setInputValues={setPages}
          edit={false}
          view={false}
        />
      ) : null}
      {openConfigs
        ? <SettingsModal
          openConfigs={openConfigs}
          setOpenConfigs={setOpenConfigs}
          georeference={georeference}
          setGeoreference={setGeoreference}
          revisionPolicy={revisionPolicy}
          setRevisionPolicy={setRevisionPolicy}
          verticalForm={verticalForm}
          setVerticalForm={setVerticalForm}
          formType={formType}
        />
        : null}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default CreateTask;
