import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box, IconButton, Paper, Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { SUPER_ADMIN } from '../../utils/const/user';
import Changes from '../../components/UploadView/Changes';

import { updateLocationProjectsChanges, uploadLocationProjectsFile } from '../../requests/api/location-projects';

import useStyles from './styles';

function LocationProjectsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'location',
      label: t('locations.importViews.locationProjects.location'),
    },
    {
      id: 'branchCode',
      label: t('locations.importViews.locationProjects.branchCode'),
    },
    {
      id: 'project',
      label: t('locations.importViews.locationProjects.project'),
    },
  ];

  const properties = ['Location', 'BranchCode', 'Project'];

  // Instructions data for the template.
  const instructions = [
    ['Instrucciones'],
    ['1. Completa la hoja "Sucursales-Proyectos" con los datos de sucursales y proyectos.'],
    ['2. En la columna "location" agrega los códigos internos de las sucursales y en la columna "project" agrega el nombre del proyecto.'],
    ['En Administración podrás verificar los nombres de los proyectos.'],
    ['3. Si el código interno de la sucursal es un número verificar que la información se guarde en formato texto.'],
    ['4. Guarda el archivo en formato xlsx o csv (con delimitador ",").'],
    [],
    // Example data
    ['Ejemplo'],
    ['location', 'project'],
    ['108032', 'Mistery Shopper'],
    ['J512', 'Ariztia'],
  ];

  return (
    <div className={classes.content}>
      <Box className={classes.backButton}>
        <IconButton component={Link} to={'../../../locations'}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography className={classes.title} variant="h3" color="textPrimary">
          {t('locations.importViews.locationProjects.uploadFile')}
        </Typography>
      </Box>
      <Box className={classes.noteContainer}>
        <Typography variant="p" color="textPrimary">
          {t('locations.importViews.locationProjects.fileNote')}
          <br />
          {t('locations.importViews.references')}
        </Typography>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceNew} /> {t('locations.importViews.new')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceEqual} /> {t('locations.importViews.equal')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceDelete} /> {t('locations.importViews.delete')}
        </Box>
      </Box>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          templateColumns={['location', 'project']}
          templateName="location-projects"
          templateSheetName="Sucursales-Proyectos"
          instructions={instructions}
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateLocationProjectsChanges}
          uploadFunction={uploadLocationProjectsFile}
        />
      </Paper>
    </div>
  );
}

export default LocationProjectsImportView;
