import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, IconButton, TextField, Tooltip,
  List, ListItem, ListItemText, Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';
import Filter2Icon from '@material-ui/icons/Filter2';
import EmailsBackup from '../../EmailsBackup';

import { VIEW_MODE, EDIT_MODE } from '../../../utils/const/taskModes';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';

function Video({
  identifier, pages, info, setUpdate, handleDeleteQuestion, mode, handleDuplicateQuestion,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});
  const [openEmails, setOpenEmails] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    const currentInfo = info || pages[identifier - 1];

    if (currentInfo) {
      setImage({
        ...image,
        url: currentInfo.imageUrl,
        name: currentInfo.imageKey,
      });

      const emails = (currentInfo.QuestionEmails
        && currentInfo.QuestionEmails[0] > 0
        && currentInfo.QuestionEmails[0].emails)
        || (currentInfo.questionEmails
        && currentInfo.questionEmails[0] > 0
        && currentInfo.questionEmails[0].emails);

      if (emails) {
        setEmailsArray(emails.split(','));
      }

      setSendEmail(currentInfo.sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const {
      id, checked, name, value, imageUrl,
    } = event.target;

    let key = id;
    let fixedValue = value;
    if (name === 'memoryUnit' || name === 'videoQuality') {
      key = name;
    } else if (name === 'requiresTeleaudit') {
      key = name;
      fixedValue = checked;
    }

    setUpdate((prevState) => !prevState);

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [key]: fixedValue,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t('task.taskEdition.videoText.title')}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          <TextField
            id="description"
            placeholder={t('task.taskEdition.videoText.description')}
            className={styles.description}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 140 }}
            value={info ? info.description : pages[identifier - 1].description || undefined}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          <div className={styles.memoryContainer}>
            <TextField
              id="memoryQuantity"
              type="number"
              placeholder={t('task.taskEdition.videoText.minMemory')}
              className={styles.memoryInput}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <Select
                    name="memoryUnit"
                    value={info && info.minMemory ? info.minMemory.split(' ')[1] : pages[identifier - 1].memoryUnit || 'KB'}
                    onChange={mode === EDIT_MODE ? handleOnChange : null}
                    disabled={mode === VIEW_MODE}
                    className={styles.memoryUnit}
                    disableUnderline
                  >
                    <MenuItem value="KB">KB</MenuItem>
                    <MenuItem value="MB">MB</MenuItem>
                    <MenuItem value="GB">GB</MenuItem>
                  </Select>
                ),
              }}
              value={info && info.minMemory ? info.minMemory.split(' ')[0] : pages[identifier - 1].memoryQuantity || undefined}
              onChange={mode === EDIT_MODE ? handleOnChange : null}
              disabled={mode === VIEW_MODE}
            />
          </div>
          <div className={styles.qualityContainer}>
            <Select
              name="videoQuality"
              value={info ? info.videoQuality : pages[identifier - 1].videoQuality || ''}
              onChange={mode === EDIT_MODE ? handleOnChange : null}
              disabled={mode === VIEW_MODE}
              className={styles.memoryUnit}
              disableUnderline
              displayEmpty
            >
              <MenuItem value="" disabled hidden className={styles.placeholder}>
                {t('task.taskEdition.videoText.videoQuality')}
              </MenuItem>
              <MenuItem value="low">{t('task.taskEdition.videoText.low')}</MenuItem>
              <MenuItem value="medium">{t('task.taskEdition.videoText.medium')}</MenuItem>
              <MenuItem value="high">{t('task.taskEdition.videoText.high')}</MenuItem>
              <MenuItem value="veryHigh">{t('task.taskEdition.videoText.veryHigh')}</MenuItem>
            </Select>
          </div>
          {/* Switch to mark this question as a teleaudit question */}
          <div className={styles.switch}>
            <FormControlLabel
            control={
              <Switch
                checked={info ? info.requiresTeleaudit : pages[identifier - 1].requiresTeleaudit}
                name="requiresTeleaudit"
                color="primary"
                onChange={mode === EDIT_MODE ? handleOnChange : null}
                disabled={mode === VIEW_MODE}
                size="small"
              />
            }
            label={t('task.taskEdition.videoText.teleauditQuestion')}
            labelPlacement="start"
            />
          </div>
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('task.taskForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('task.taskForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <>
          <Tooltip title={t('task.taskEdition.duplicate')}>
            <IconButton
              id={identifier}
              onClick={handleDuplicateQuestion}
              className={styles.deleteButton}
            >
              <Filter2Icon />
            </IconButton>
          </Tooltip>
          <IconButton
            id={identifier}
            onClick={handleDeleteQuestion}
            className={styles.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default Video;
