import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import useStyles from './styles';

const MetricsTable = ({
  record, target, lineList, shareType, facialScores, productName, update,
}) => {
  const { t } = useTranslation();
  const [averageSOS, setAverageSOS] = useState(0);
  const [averageRivalrySOS, setAverageRivalrySOS] = useState(0);

  const lineLengthCalculator = (item) => {
    let value = 0;
    if (shareType === 'volumetric-share') {
      const height = item.x2 - item.x1;
      const width = item.y2 - item.y1;
      value = height * width;
    } else if (shareType === 'linear-share') {
      value = Math.hypot(item.x2 - item.x1, item.y2 - item.y1);
    } else if (shareType === 'facial-share') {
      value = item.presence - item.total;
    }
    return value;
  };

  useEffect(() => {
    if (lineList && shareType !== 'facial-share') {
      let total = 0;
      let imageLineCount = 0;
      let totalRivalry = 0;
      Object.keys(lineList).forEach((key) => {
        if (lineList[key].length !== 0) {
          imageLineCount += 1;
          let imageTray = 0;
          let imagePresence = 0;
          let imageRivarly = 0;
          lineList[key].forEach((item) => {
            const lineLength = lineLengthCalculator(item);
            if (item.color === 'blue') {
              imageTray += lineLength;
            } else if (item.color === 'red') {
              imagePresence += lineLength;
            } else if (item.color === 'green') {
              imageRivarly += lineLength;
            }
          });
          total += (imagePresence / imageTray);
          totalRivalry += (imageRivarly / imageTray);
        }
      });
      if (imageLineCount !== 0) {
        setAverageSOS(((total / imageLineCount) * 100).toFixed(0));
        setAverageRivalrySOS(((totalRivalry / imageLineCount) * 100).toFixed(0));
      } else {
        setAverageSOS(0);
      }
    }
  }, [lineList, update]);

  useEffect(() => {
    if (shareType === 'facial-share') {
      let imageTray = 0;
      let imagePresence = 0;
      Object.keys(facialScores).forEach((key) => {
        imageTray += facialScores[key].total;
        imagePresence += facialScores[key].presence;
      });
      if (imageTray === 0) {
        setAverageSOS(0);
      } else {
        setAverageSOS(((imagePresence / imageTray) * 100).toFixed(0));
      }
    }
  }, [facialScores]);

  const handleShareType = () => {
    switch (shareType) {
      case 'volumetric-share':
        return (
          <>{t('teleaudit.table.volumetricType')}</>
        );
      case 'facial-share':
        return (
          <>{t('teleaudit.table.facingType')}</>
        );
      default:
        return (
          <>{t('teleaudit.table.linearType')}</>
        );
    }
  };

  const styles = useStyles();
  // Trabajar el record
  const tableHeaders = [
    {
      id: 'player',
      label: <p>{t('teleaudit.table.product')}</p>,
    },
    {
      id: 'SOS-percent',
      label: <p> SOS%</p>,
    },
    {
      id: 'target',
      label: <p> Target</p>,
    },
    {
      id: 'last-month',
      label: <p> LM</p>,
    },
    {
      id: 'last-3-months',
      label: <p> L3M</p>,
    },
  ];

  return (
    <>
      <Typography variant='h4' className={styles.metricsSubheader}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            Share Of Shelf
          </Grid>
          <Grid item xs={4}>
            <p
              align="center"
              className={`${styles.tag} ${styles.tagWaitinfForApproval}`}>
              <b>
                {handleShareType()}
              </b>
            </p>
          </Grid>
        </Grid>
      </Typography>
      <Divider style={{ height: '2px' }}/>
      <Table style={{ minWidth: 100 }} size="small" aria-label="simple table">
          <TableRow className={styles.tableRow}>
            {tableHeaders.map((tableHeader) => (
              <TableCell
                key={tableHeader.id}
                padding="normal"
              >
                {tableHeader.label}
              </TableCell>
            ))}
          </TableRow>
        <TableBody>
          <TableRow className={styles.tableRow}>
            <TableCell> {productName} </TableCell>
            <TableCell>
            <p className={clsx({
              [styles.redSOS]: target > averageSOS,
              [styles.greenSOS]: !(target > averageSOS),
            })}
            >
              { averageSOS } %
            </p>
            </TableCell>
            <TableCell> {target}% </TableCell>
            <TableCell> {(record.monthAgoRecord * 100).toFixed(0)}% </TableCell>
            <TableCell> {(record.threeMonthsAgoRecord * 100).toFixed(0)}% </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell> Comp. </TableCell>
            <TableCell> {averageRivalrySOS}% </TableCell>
            <TableCell> N/A </TableCell>
            <TableCell>
              {record.monthAgoRecord > 0
                ? (100 - (record.monthAgoRecord * 100)).toFixed(0)
                : 0}%
            </TableCell>
            <TableCell>
              {record.threeMonthsAgoRecord > 0
                ? (100 - (record.threeMonthsAgoRecord * 100)).toFixed(0)
                : 0}%
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default MetricsTable;
