/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import {
  MULTIPLE_IMAGE_QUESTION,
} from '../../../../utils/const/pageTypes';

import {
  postTeleauditShareResponse,
  deactivateTeleauditShareResponses,
} from '../../../../requests/api/teleaudit-share-response';
import {
  postTeleauditBinaryResponse,
  deactivateTeleauditBinaryResponses,
} from '../../../../requests/api/teleaudit-binary-response';
import getTeleauditKpiByQuestion from '../../../../requests/api/teleaudit-kpi';
import {
  getQuestionResponseById,
  putQuestionReponseTeleauditStatus,
} from '../../../../requests/api/question-response';
import { getShareOfShelfRecord } from '../../../../requests/api/question';
import getImagesByQuestionResponseId from '../../../../requests/api/question-response-image';
import { postQuestionResponseImageVectors } from '../../../../requests/api/question-response-image-vector';
import { getCompanyById } from '../../../../requests/api/company';

import TeleauditCarousel from './Components/teleauditCarousel';
import BinaryTable from './Components/binaryTable';
import MetricsTable from './Components/metricsTable';
import ImageButtons from './Components/imageButtons';
import SurveyImage from './Components/surveyImage';
import useStyles from './styles';

const TeleauditAll = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [lineList, setLineList] = useState({});
  const [questionType, setQuestionType] = useState('');
  const [teleauditKpis, setTeleauditKpis] = useState(null);
  const [questionResponse, setQuestionResponse] = useState(null);
  // const [category, setCategory] = useState(null);
  const [switchValues, setSwitchValues] = useState({});
  const [notAnswered, setNotAnswered] = useState(0);
  const [saving, setSaving] = useState(false);
  const [scores, setScores] = useState({});
  const [record, setRecord] = useState([]);
  const [shareType, setShareType] = useState('');
  const [lines, setLines] = useState([]);
  const [buttonColor, setButtonColor] = useState(null);
  const [erase, setErase] = useState(false);
  const [listImages, setListImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState({});
  const [facialScores, setFacialScores] = useState({});
  const [saveLoader, setSaveLoader] = useState('');
  const [update, setUpdate] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const company = useSelector((state) => state.company);

  const { taskResponseId, questionResponseId } = useParams();

  useEffect(() => {
    const getQuestionResponse = async () => {
      const response = await getQuestionResponseById({ questionResponseId });

      if (response.data.data.questionResponse) {
        setQuestionType(response.data.data.questionResponse.Question.type);
        setQuestionResponse(response.data.data.questionResponse);
      }
      // if (response.data.data.category) {
      //   setCategory(response.data.data.category);
      // }
      if (['not-required'].includes(response.data.data.questionResponse.teleauditStatus)) {
        window.location.href = '/home';
      }
    };

    const findSurveyResponseImages = async () => {
      const response = await getImagesByQuestionResponseId({
        questionResponseId,
      });

      if (response.success) {
        const { data } = response.data;
        setListImages(data);
        const newObject = {};
        data.forEach((image) => {
          const vectors = [];
          if (image.QuestionResponseImageVectors.length > 0) {
            for (let i = 0; i < image.QuestionResponseImageVectors.length; i += 1) {
              const vector = image.QuestionResponseImageVectors[i];
              const newLine = {
                id: vector.id,
                x1: vector.startX,
                y1: vector.startY,
                x2: vector.endX,
                y2: vector.endY,
                color: vector.color,
                saved: true,
              };
              vectors.push(newLine);
            }
          }
          newObject[image.id] = vectors;
        });
        setLineList(newObject);
      } else {
        window.location.href = '/home';
      }
    };
    getQuestionResponse();
    findSurveyResponseImages();
  }, []);

  useEffect(() => {
    setCurrentImage(listImages[0]);
    const facialScoresObject = {};
    Object.keys(listImages).forEach((item) => {
      facialScoresObject[[listImages[item].id]] = { presence: 0, total: 0 };
    });
    setFacialScores(facialScoresObject);
  }, [listImages]);

  useEffect(() => {
    if (company) {
      const getCompany = async () => {
        const { data } = await getCompanyById(company.companyId);
        if (data.success) {
          setCompanyLogo(data.data.company.logoUrl);
        }
      };
      getCompany();
    }
  }, []);

  useEffect(() => {
    if (questionResponse) {
      const findRecord = async () => {
        const { questionId } = questionResponse;
        const { data } = await getShareOfShelfRecord({ questionId });
        let monthAgoRecord = 0;
        let threeMonthsAgoRecord = 0;
        const monthAgoLen = Object.keys(data.data.monthAgo).length;
        const threeMonthsAgoLen = Object.keys(data.data.threeMonths).length;
        Object.keys(data.data.monthAgo).forEach((id) => {
          monthAgoRecord += data.data.monthAgo[id].score;
        });
        Object.keys(data.data.threeMonths).forEach((id) => {
          threeMonthsAgoRecord += data.data.threeMonths[id].score;
        });
        setRecord({
          monthAgoRecord: monthAgoLen > 0 ? monthAgoRecord / monthAgoLen : 0,
          threeMonthsAgoRecord: threeMonthsAgoLen > 0
            ? threeMonthsAgoRecord / threeMonthsAgoLen : 0,
        });
      };
      findRecord();
    }
  }, [questionResponse]);

  useEffect(() => {
    if (questionResponse) {
      const getTeleauditKpis = async () => {
        const { questionId } = questionResponse;
        const response = await getTeleauditKpiByQuestion({ questionId });
        const teleauditBinaryKpisObject = {};
        response.data.teleauditBinaryKpis.forEach((element) => {
          teleauditBinaryKpisObject[element.id] = element;
        });
        if (questionResponse.Question.type === MULTIPLE_IMAGE_QUESTION) {
          if (Object.keys(teleauditBinaryKpisObject).length !== 0) {
            const teleauditKpisObject = {
              binary: teleauditBinaryKpisObject,
              share: response.data.teleauditKpiShare || {
                KpiDetail: {
                  target: 0,
                },
              },
            };
            setTeleauditKpis(teleauditKpisObject);
          } else {
            const teleauditKpisObject = {
              binary: null,
              share: response.data.teleauditKpiShare || {
                KpiDetail: {
                  target: 0,
                },
              },
            };
            setTeleauditKpis(teleauditKpisObject);
          }
        } else {
          const teleauditKpisObject = {
            binary: teleauditBinaryKpisObject,
            share: {
              KpiDetail: {
                target: 0,
              },
            },
          };
          setTeleauditKpis(teleauditKpisObject);
        }
      };
      getTeleauditKpis();
    }
  }, [questionResponse]);

  useEffect(() => {
    if (teleauditKpis) {
      const switchValuesObject = {};
      Object.keys(teleauditKpis.binary).forEach((item) => {
        switchValuesObject[item] = false;
      });
      setSwitchValues(switchValuesObject);
      if (questionType === MULTIPLE_IMAGE_QUESTION) {
        setShareType(teleauditKpis.share.KpiDetail.teleauditType);
      }
      setLoading(false);
    }
  }, [teleauditKpis]);

  const saveAll = async () => {
    await setSaveLoader('loading');
    const teleauditStatus = 'done';
    // Save vectors data of TA.
    if (shareType === 'linear-share' || shareType === 'volumetric-share') {
      const response = await postQuestionResponseImageVectors({ lineList, shareType });
      if (!response.success) {
        setSaveLoader('loaded');
        return;
      }
    }
    await putQuestionReponseTeleauditStatus({
      questionResponseId,
      teleauditStatus,
      taskResponseId,
      // When all the question responses are done, the task instance is done too
      last: notAnswered === 0,
    });
    if (questionResponse.teleauditStatus === 'reaudit') {
      const { id } = questionResponse;
      await deactivateTeleauditShareResponses({ questionResponseId: id });
      await deactivateTeleauditBinaryResponses({ questionResponseId: id });
    }
    if (questionType === MULTIPLE_IMAGE_QUESTION) {
      const teleauditShareKpi = teleauditKpis.share.id;
      Object.keys(scores).forEach(async (element) => {
        const questionImageResponseId = element;
        if (scores[element].presence) {
          await postTeleauditShareResponse(
            teleauditShareKpi,
            questionImageResponseId,
            scores[element].presence,
          );
        }
      });
    }
    Object.keys(switchValues).forEach(async (element) => {
      await postTeleauditBinaryResponse(
        element,
        questionResponseId,
        switchValues[element],
      );
    });
    setSaveLoader('loaded');
  };

  useEffect(() => {
    if (erase) {
      setButtonColor(null);
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'context-menu';
    }
  }, [erase]);

  useEffect(() => {
    if (buttonColor) {
      setErase(false);
    }
  }, [buttonColor]);

  const scoreCalculator = (linesObject) => {
    let tray = 0;
    let presence = 0;
    let rivalry = 0;
    if (shareType === 'facial-share') {
      return ((linesObject.presence / linesObject.total)).toFixed(3);
    }
    linesObject.forEach((line) => {
      let value = 0;
      if (shareType === 'volumetric-share') {
        const height = Math.abs(line.x2 - line.x1);
        const width = Math.abs(line.y2 - line.y1);
        value = height * width;
      } else if (shareType === 'linear-share') {
        value = Math.hypot(line.x2 - line.x1, line.y2 - line.y1);
      }
      if (line.color === 'blue') {
        tray += value;
      } else if (line.color === 'red') {
        presence += value;
      } else if (line.color === 'green') {
        rivalry = presence - value;
      }
    });
    return { presence: (presence / tray).toFixed(3), rivalry: (rivalry / tray).toFixed(3) };
  };

  const saveAllValidation = () => {
    if (questionType === MULTIPLE_IMAGE_QUESTION) {
      const scoreObject = {};
      let objectReview = {};
      if (shareType === 'facial-share') {
        objectReview = facialScores;
      } else {
        objectReview = lineList;
      }
      let notAnsweredCount = 0;
      Object.keys(objectReview).forEach(async (element) => {
        const score = scoreCalculator(objectReview[element]);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(score.presence)) {
          notAnsweredCount += 1;
        } else {
          scoreObject[element] = { presence: score.presence, rivalry: score.rivalry };
        }
      });
      setNotAnswered(notAnsweredCount);
      setScores(scoreObject);
      setSaving(true);
    } else {
      setNotAnswered(0);
      setSaving(true);
    }
  };

  const onCloseSaving = () => {
    setSaving(false);
  };
  return (
    <div className={styles.content}>
      <Grid container spacing={5}>
        <Grid item xs={5} style={{ paddingLeft: '5%' }}>
          <div className={styles.header}>
            <Box sx={{ width: '100%' }}>
              <Typography variant='h1' gutterBottom className={styles.title}>
                {t('teleaudit.title')}
              </Typography>
              <Typography variant='h4' className={styles.title}>
                {questionResponse ? questionResponse.Question.title : null}
              </Typography>
              <Typography variant='h4' className={styles.title}>
                {t('teleaudit.subtitle')}
                {listImages.length}
                {listImages.length === 1
                  ? t('teleaudit.image')
                  : t('teleaudit.images')
                }
              </Typography>
            </Box>
          </div>
          <Grid container spacing={5} style={{ paddingTop: '3%' }}>
            <Grid item xs={6}>
              <Typography variant='h4' className={styles.subheader} style={{ paddingBottom: '5px' }}>
              {t('teleaudit.company')}
              </Typography>
              <Divider style={{ height: '2px' }} />
              <Typography variant='h4' className={styles.subDivider} align='center'>
                { companyLogo
                  ? (
                    <img
                      alt='company-logo'
                      src={companyLogo}
                      width='70%'
                      height='70%'
                      align='center'
                    />
                  )
                  : null
                }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h4' className={styles.subheader} style={{ paddingBottom: '5px' }}>
                {t('teleaudit.category')}
              </Typography>
              <Divider style={{ height: '2px' }}/>
              <Typography variant='h4' className={styles.subDivider}>
                { questionResponse && questionResponse.SurveyResponse.TaskInstance.Category
                  ? questionResponse.SurveyResponse.TaskInstance.Category.name
                  : t('teleaudit.noCategory')}
              </Typography>
            </Grid>
          </Grid>
          <Box style={{ paddingTop: '5%' }}>
            { teleauditKpis && !loading
              ? (
                <BinaryTable
                teleauditKpis={teleauditKpis}
                switchValues={switchValues}
                setSwitchValues={setSwitchValues}
                referenceImage={questionResponse.Question.planogramUrl}
                />
              )
              : null
            }
          </Box>
          <Box style={{ paddingTop: '3%' }}>
            {teleauditKpis && questionType === MULTIPLE_IMAGE_QUESTION && !loading
              ? <MetricsTable
                  shareType={shareType}
                  record={record}
                  target={teleauditKpis.share.KpiDetail.target}
                  lineList={lineList}
                  lines={lines}
                  facialScores={facialScores}
                  productName={questionResponse.SurveyResponse.Product ? questionResponse.SurveyResponse.Product.name : '-'}
                  update={update}
                />
              : null}
          </Box>
        </Grid>
        <Grid item xs={2} >
          <Box style={{ minHeight: '400px' }}>
            { questionType === MULTIPLE_IMAGE_QUESTION && !loading
              ? <ImageButtons
                  buttonColor = {buttonColor}
                  onColorChange={setButtonColor}
                  onErase={setErase}
                  erase={erase}
                  setLines={setLines}
                  shareType={shareType}
                  taskResponseId={taskResponseId}
                  saveAllValidation={saveAllValidation}
                  facialScores={facialScores}
                  setFacialScores={setFacialScores}
                  currentImage={currentImage}
                  questionType={questionType}
                  />
              : null}
            </Box>
            <Box style={{ minHeight: '260px' }} >
              <Box position='absolute' bottom='150px'>
                <Button
                  align='right'
                  id="back-btn"
                  text={t('teleaudit.buttons.back')}
                  variant="contained"
                  height="40px"
                  width="170px"
                  backgroundColor='#2C9DC9'
                  color='white'
                  borderRadius='10px'
                  component={Link}
                  to={`/teleaudit/${taskResponseId}`}
                  />
                <Button
                  id="save-all-btn"
                  text={t('teleaudit.buttons.saveAll')}
                  variant="contained"
                  height="40px"
                  width="170px"
                  backgroundColor='#2C9DC9'
                  color='white'
                  borderRadius='10px'
                  onClick={() => {
                    saveAllValidation();
                  }}
                  />
              </Box>
            </Box>
        </Grid>
        <Grid item xs={5}>
          { currentImage && !loading
            ? (
              <Box >
                <SurveyImage
                  buttonColor={buttonColor}
                  lines={lines}
                  setLines={setLines}
                  erase={erase}
                  shareType={shareType}
                  questionResponseImage={currentImage}
                  lineList={lineList}
                  setLineList={setLineList}
                  update={update}
                  setUpdate={setUpdate}
                />
              </Box>
            )
            : null
          }
          <Box pt={1}>
            { questionType === MULTIPLE_IMAGE_QUESTION && !loading
              ? <TeleauditCarousel
                  loading={loading}
                  listImages={listImages}
                  setCurrentImage={setCurrentImage}
                  setLines={setLines}
                  lineList={lineList}
                  currentImage={currentImage}
                />
              : null}
          </Box>
        </Grid>
      </Grid>
      {saving ? (
        <Modal
          title={saveLoader === '' ? t('teleaudit.confirmModal.sureQuestion') : null}
          defaultOpen={saving}
          // setCurrentOpen={setSaving}
          setCurrentOpen={saveLoader === 'loaded' ? null : setSaving}
          hideButton={true}
        >
          {saveLoader === ''
            ? <div className={styles.modalForm} >
                { notAnswered > 0
                  ? (
                    <div align='center' className={styles.confirmModal}>
                      <Typography variant='h4' className={styles.title}>
                        {t('teleaudit.confirmModal.quantityPartOne')}
                        {notAnswered}
                        {notAnswered === 1
                          ? t('teleaudit.confirmModal.quantityUnique')
                          : t('teleaudit.confirmModal.quantityPlural')
                        }
                        {t('teleaudit.confirmModal.quantityNotAnswered')}
                      </Typography>
                    </div>
                  )
                  : null
                }
                <Grid alignItems='center' spacing={1} container>
                  <>
                    <Grid item xs={6}>
                      <Button
                        id='close-modal-btn'
                        onClick={onCloseSaving}
                        variant='contained'
                        backgroundColor="#E74C3C"
                        text={t('teleaudit.buttons.back')}
                      >
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        id='save-all-confirmed-btn'
                        onClick={saveAll}
                        backgroundColor='#2C9DC9'
                        text={t('teleaudit.buttons.confirm')}
                        variant='contained'
                      >
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </div>
            : <>
              {saveLoader === 'loading'
                ? <div className={styles.modalForm} >
                    <div style={{ paddingTop: '5%' }} align='center'>
                      <CircularProgress align='center'/>
                    </div>
                  </div>
                : <div className={styles.secondModalForm} >
                    <div align='center' className={styles.confirmModal}>
                      <Typography variant='h4' className={styles.title} align='center'>
                        {t('teleaudit.confirmModal.success')}
                      </Typography>
                    </div>
                  <Grid alignItems='center' spacing={1} container>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4} align='center' style={{ paddingTop: '3%' }}>
                        <Button
                          id='close-modal-btn'
                          variant='contained'
                          with='100%'
                          backgroundColor="#2C9DC9"
                          text={t('teleaudit.confirmModal.button')}
                          component={Link}
                          to={`/teleaudit/${taskResponseId}`}
                        >
                        </Button>
                      </Grid>
                  </Grid>
                  </div>
                }
              </>}
        </Modal>
      ) : null}
    </div>

  );
};

export default TeleauditAll;
