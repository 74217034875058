import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  Button,
  Grid,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';

// icons
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import DeleteIcon from '@material-ui/icons/Close';
import Filter2Icon from '@material-ui/icons/Filter2';

import Select from '../../Select';
import Modal from '../../Modal';

import EmojiForm from './emojiForm';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

import {
  EDIT_MODE, VIEW_MODE,
} from '../../../utils/const/taskModes';

import { S3_EMOJIS_BUCKET, emojiSchema } from '../../../utils/const/emojis';

function Satisfaction({
  identifier, pages, info, setUpdate, handleDeleteQuestion, editMode,
  mode, handleDuplicateQuestion,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [checkBox, setCheckBox] = useState(null);

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);

  const [file, setFile] = useState({});
  const [image, setImage] = useState({});

  const [feedbackScale, setfeedbackScale] = useState(info ? info.emojiScale : '');
  const [emojiSet, setEmojiSet] = useState(info ? info.emojiSet : 'emojiSet1');
  const [openEmojiSet, setOpenEmojiSet] = useState(false);
  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
    } else {
      if (pages && pages[identifier - 1].imageUrl) {
        setImage({
          ...image,
          url: pages[identifier - 1].imageUrl,
          name: pages[identifier - 1].imageKey,
        });
      }

      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    }
    // Se busca que en modo EDICIÓN se cargue el feedbackScale
    // es modo EDICIÓN si se entrega deleted questions.
    if (editMode) {
      if (pages[identifier - 1].emojiScale) {
        setfeedbackScale(pages[identifier - 1].emojiScale);
      } else {
        setfeedbackScale('');
      }
    }
  }, []);

  const handleCheck = (event) => {
    const { name } = event.target;

    setCheckBox(name);
  };

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    // let key = 'title';
    // if (id === 'imageKey') {
    //   key = id;
    // }

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [id]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  const handleFeedbackScale = (event) => {
    const { value } = event.target;
    const aux = Number.parseInt(value, 10);
    setfeedbackScale(aux);

    setUpdate((prevState) => !prevState);

    const key = 'emojiScale';
    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [key]: value,
      emojiSet,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
    };
  };

  const handleEmojiSet = (value) => {
    setUpdate((prevState) => !prevState);
    const key = 'emojiSet';
    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [key]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
    };
  };

  const openEmojis = () => {
    setOpenEmojiSet(true);
  };

  const concatString = (first, second, third, fourth) => first + second + third + fourth;
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t('task.taskEdition.satisfactionText.question')}
            value={info ? info.title : pages[identifier - 1].title}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          <Select
            name="feedbackOptions"
            className={styles.description}
            items={[{ name: t('task.taskEdition.satisfactionText.select'), value: '' },
              { name: t('task.taskEdition.satisfactionText.twoOptions'), value: '2' },
              { name: t('task.taskEdition.satisfactionText.threeOptions'), value: '3' },
              { name: t('task.taskEdition.satisfactionText.fourOptions'), value: '4' },
              { name: t('task.taskEdition.satisfactionText.fiveOptions'), value: '5' },
              { name: t('task.taskEdition.satisfactionText.sixOptions'), value: '6' }]}
            value={info ? info.feedbackScale : feedbackScale}
            onChange={(event) => handleFeedbackScale(event)}
            fullWidth
            disabled={mode === VIEW_MODE}
          />
          <Grid container className={styles.emojiGrid}>
            {feedbackScale !== '' && emojiSchema[feedbackScale] ? emojiSchema[feedbackScale].map((item) => (
              <Grid key={item} className={styles.labels} item xs>
                <div key={item} className={styles.checkboxesContainer}>
                  <FormControlLabel
                    className={styles.checkbox}
                    control={
                      <div>
                        <Checkbox
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<RadioButtonChecked style={{ color: 'green' }} />}
                          name="1"
                          checked={checkBox === '1'}
                          disabled
                        />
                      </div>
                    }
                    label={<Avatar
                      className={styles.avatar}
                      src={concatString(S3_EMOJIS_BUCKET, '/', emojiSet, item)}
                    />}
                    onChange={handleCheck}
                  />

                </div>
              </Grid>
            )) : null}
          </Grid>
          <Grid container direction='column'>
            <Grid item xs>
              {mode !== VIEW_MODE ? (
                <>
                  <Button
                    onClick={() => setOpen(true)}
                  >
                    {t('task.taskForm.addMediaTitle')}
                  </Button>
                  <Button
                    onClick={() => setOpenEmails(true)}
                  >
                    {t('task.taskForm.addEmails')}
                  </Button>
                </>
              ) : null
              }
            </Grid>
            <Grid item xs>
              <Button
                onClick={() => openEmojis()}
                disabled={mode === VIEW_MODE}
              >
                {t('task.taskEdition.satisfactionText.editEmojiSet')}
              </Button>
            </Grid>
          </Grid>
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <>
          <Tooltip title={t('task.taskEdition.duplicate')}>
            <IconButton
              id={identifier}
              onClick={handleDuplicateQuestion}
              className={styles.deleteButton}
            >
              <Filter2Icon />
            </IconButton>
          </Tooltip>
          <IconButton
            id={identifier}
            onClick={handleDeleteQuestion}
            className={styles.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
      {openEmojiSet ? (
        <Modal title={t('task.taskEdition.satisfactionText.selectEmojiSet')} defaultOpen={openEmojiSet} setCurrentOpen={setOpenEmojiSet}>
          <EmojiForm
            setCurrentOpen={setOpenEmojiSet}
            emojiSet={emojiSet}
            setEmojiSet={setEmojiSet}
            handleEmojiSet={handleEmojiSet}
            bucket={S3_EMOJIS_BUCKET}
          />
        </Modal>
      ) : null}
    </div>
  );
}

export default Satisfaction;
