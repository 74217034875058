import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  IconButton, TextField, Button,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';

import { EDIT_MODE, VIEW_MODE } from '../../../utils/const/taskModes';

import EmailsBackup from '../../EmailsBackup';

import useStyles from '../styles';

function ResumePage({
  identifier, pages, info, setUpdate, handleDeleteQuestion,
  mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    const currentInfo = info || pages[identifier - 1];

    if (currentInfo) {
      setImage({
        ...image,
        url: currentInfo.imageUrl,
        name: currentInfo.imageKey,
      });

      const emails = (currentInfo.QuestionEmails
        && currentInfo.QuestionEmails[0] > 0
        && currentInfo.QuestionEmails[0].emails)
        || (currentInfo.questionEmails
        && currentInfo.questionEmails[0] > 0
        && currentInfo.questionEmails[0].emails);

      if (emails) {
        setEmailsArray(emails.split(','));
      }

      setSendEmail(currentInfo.sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;
    setUpdate((prevState) => !prevState);

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [id]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  return (
    <div className={styles.root}>
      {/* <img src="/TCGHRES2021BACK2MainBlue.png" alt="TCG" /> */}
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.welcomeTitle}
            placeholder={t('task.taskEdition.resumePageText.title')}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('task.taskForm.addEmails')}
              </Button>
            </>
          ) : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default ResumePage;
