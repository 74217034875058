/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Checkbox, FormControlLabel, TextField, IconButton,
  Tooltip, Grid, List, ListItem, ListItemText, Typography,
} from '@material-ui/core';

// icons
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DeleteIcon from '@material-ui/icons/Close';
import Filter2Icon from '@material-ui/icons/Filter2';

import useStyles from '../styles';
import { ENTRENAMIENTO } from '../../../utils/const/formTypes';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';
import Modal from '../../Modal';
import ModalButton from '../../Button';

import {
  EDIT_MODE, VIEW_MODE,
} from '../../../utils/const/taskModes';

function YesNoQuestion({
  identifier, pages, info, setUpdate, formType, handleDeleteQuestion,
  mode, switchStock, setSwitchStock, isSwitchStock, handleDuplicateQuestion,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [correct, setCorrect] = useState(['1']);

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});
  const [switchStockConfirmation, setSwitchStockConfirmation] = useState(false);
  const [tempSwitchStock, setTempSwitchStock] = useState(null);

  const [options] = useState(info ? info.QuestionChoices
    : [
      {
        identifier: 1,
        title: t('task.taskEdition.yesNoQuestionText.yes'),
        type: 'text',
      },
      {
        identifier: 2,
        title: 'No',
        type: 'text',
      },
    ]);

  if (pages) {
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      questionChoices: options,
      questionAnswer: correct,
    };
  }

  useEffect(() => {
    const currentInfo = info || pages[identifier - 1];

    if (currentInfo) {
      setImage({
        ...image,
        url: currentInfo.imageUrl,
        name: currentInfo.imageKey,
      });

      const emails = (currentInfo.QuestionEmails
        && currentInfo.QuestionEmails[0] > 0
        && currentInfo.QuestionEmails[0].emails)
        || (currentInfo.questionEmails
        && currentInfo.questionEmails[0] > 0
        && currentInfo.questionEmails[0].emails);

      if (emails) {
        setEmailsArray(emails.split(','));
      }

      setSendEmail(currentInfo.sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    if (id === 'title' || id === 'description' || id === 'imageKey'
      || id === 'sendEmail' || id === 'questionEmails') {
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        [id]: value,
        questionLogic: [{
          questionIdentifier: identifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
        }],
        questionAnswer: correct,
        imageUrl,
      };
    } else {
      const indexOption = options.findIndex((option) => option.identifier === id);

      if (indexOption !== -1) options[indexOption].title = value;
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionChoices: options,
        questionAnswer: correct,
      };
    }
  };

  const handleCorrectAnswer = (event, identifierid) => {
    if (event.target.checked && formType.name === ENTRENAMIENTO) {
      setCorrect([identifierid]);
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionAnswer: correct,
      };
    }
  };

  const handleCheckSwitchStock = (event) => {
    const { checked, id } = event.target;

    if (switchStock !== null && switchStock !== id && checked) {
      setTempSwitchStock(id);
      setSwitchStockConfirmation(true);
      return;
    }

    if (checked) {
      setSwitchStock(id);
    } else {
      setSwitchStock(null);
    }
  };

  const confirmSwitchStockChange = () => {
    setSwitchStock(tempSwitchStock);
    setSwitchStockConfirmation(false);
  };

  const cancelSwitchStockChange = () => {
    setSwitchStockConfirmation(false);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            id={identifier}
            color="primary"
            name="switchStock"
            onChange={(event) => handleCheckSwitchStock(event)}
            checked={`${identifier}` === `${switchStock}` && isSwitchStock}
            disabled={!isSwitchStock}
          />
        }
        label={t('task.taskEdition.yesNoQuestionText.switchStock')}
      />
      <div className={styles.root}>
        <div className={styles.container}>
          <p className={styles.identifier}>{identifier}.</p>
          <div className={styles.questionContainer}>
            <TextField
              id="title"
              className={styles.question}
              placeholder={t('task.taskEdition.yesNoQuestionText.question')}
              value={info ? info.title : pages[identifier - 1].title}
              onChange={mode === EDIT_MODE ? handleOnChange : null}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 70 }}
              multiline
              disabled={mode === VIEW_MODE}
            />
            <TextField
              id="description"
              className={styles.description}
              placeholder={t('task.taskEdition.yesNoQuestionText.description')}
              value={info ? info.description : pages[identifier - 1].description}
              onChange={mode === EDIT_MODE ? handleOnChange : null}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 140 }}
              multiline
              disabled={mode === VIEW_MODE}
            />

            {options.map((option) => (
              <div key={option.identifier}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={styles.checkbox}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }} />}
                      name={option.identifier}
                      onClick={(event) => handleCorrectAnswer(event, option.identifier)}
                      checked={option.identifier === correct[0] && formType.name === 'entrenamiento'}
                      disabled={mode === VIEW_MODE}
                    />
                  }
                  label={(
                    <TextField
                      id={option.identifier}
                      className={styles.option}
                      value={option.title}
                      placeholder={`${t('task.taskEdition.simpleSelectionText.write')} ${t('task.taskEdition.simpleSelectionText.option')}`}
                      InputProps={{ disableUnderline: true }}
                      onChange={mode === EDIT_MODE ? handleOnChange : null}
                      disabled={true}
                      multiline
                      maxRows={5}
                      fullWidth
                    />
                  )}
                />
              </div>

            ))}
            {mode !== VIEW_MODE ? (
              <>
                <Button
                  onClick={() => setOpen(true)}
                >
                  {t('task.taskForm.addMediaTitle')}
                </Button>
                <Button
                  onClick={() => setOpenEmails(true)}
                >
                  {t('task.taskForm.addEmails')}
                </Button>
              </>
            ) : null
            }
            {image.url
              ? <>
                <Grid container>
                  <Grid item xs={1}>
                    <Tooltip
                      title={
                        <img
                          alt='reference-image'
                          src={image.url}
                          width='100%'
                          height='100%'
                        />
                      }
                      arrow
                      placement='top-end'
                    >
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='50px'
                        height='50px'
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5}>
                    <List className={styles.list}>
                      <ListItem>
                        <ListItemText
                          primary={image.name}
                          primaryTypographyProps={{ variant: 'h5' }}
                          secondary={image.size ? `  (${image.size})` : null}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </>
              : null
            }
          </div>
        </div>
        {mode !== VIEW_MODE ? (
          <>
            <Tooltip title={t('task.taskEdition.duplicate')}>
              <IconButton
                id={identifier}
                onClick={handleDuplicateQuestion}
                className={styles.deleteButton}
              >
                <Filter2Icon />
              </IconButton>
            </Tooltip>
            <IconButton
              id={identifier}
              onClick={handleDeleteQuestion}
              className={styles.deleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
        <ExampleImageQuestion
          open={open}
          setOpen={setOpen}
          file={file}
          setFile={setFile}
          handleOnChange={handleOnChange}
          setImage={setImage}
        />
        <EmailsBackup
          sendEmail={sendEmail}
          setSendEmails={setSendEmail}
          open={openEmails}
          setOpen={setOpenEmails}
          emailsArray={emailsArray}
          setEmailsArray={setEmailsArray}
          handleOnChange={handleOnChange}
          disabled={!!info}
        />
      </div>
      {switchStockConfirmation ? (
        <Modal title={t('task.taskEdition.yesNoQuestionText.modalTitle')} defaultOpen={switchStockConfirmation} setCurrentOpen={setSwitchStockConfirmation}>
          <div className={styles.dialogWrapper}>
            <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={12}>
                <Typography className={styles.dialogText} variant="h2" color="textSecondary" component="p">
                  {t('task.taskEdition.yesNoQuestionText.confirmSwitchStockChange')}
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <ModalButton
                  id="confirm-btn"
                  onClick={confirmSwitchStockChange}
                  variant="contained"
                  text={t('task.taskEdition.yesNoQuestionText.confirm')}
                  height="35px"
                  width="100%"
                  backgroundColor="#FFFFFF"
                  color="#2C9DC9"
                  borderRadius="10px"
                />
              </Grid>
              <Grid item xs={3}>
                <ModalButton
                  id="cancel-btn"
                  onClick={cancelSwitchStockChange}
                  variant="contained"
                  text={t('task.taskEdition.yesNoQuestionText.cancel')}
                  height="35px"
                  width="100%"
                  backgroundColor='#F5B7B1'
                  color='#E74C3C'
                  borderRadius="10px"
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default YesNoQuestion;
