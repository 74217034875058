import React, { useState, useEffect } from 'react';
// core
import {
  Box,
  Checkbox, FormControlLabel,
} from '@material-ui/core';
// icons
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import useStyles from './styles';

function TaskAssociationConfirm({
  associations,
}) {
  const styles = useStyles();

  const [updateCheckboxes, setUpdateCheckboxes] = useState(false);

  const handleCheck = (event, target) => {
    if (event.target.name.includes('-')) {
      const [userId, categoryId, locationId] = event.target.name.split('-');

      const userFound = associations.find((association) => `${association.id}` === userId);
      if (!userFound) return;

      const categoryFound = userFound.categories.find((c) => `${c.id}` === categoryId);
      if (!categoryFound) return;

      if (target === 'category') {
        categoryFound.checked = !categoryFound.checked;

        categoryFound.locations.forEach((l) => {
          // eslint-disable-next-line no-param-reassign
          l.checked = categoryFound.checked;
        });
      } else {
        const locationFound = categoryFound.locations.find((l) => `${l.id}` === locationId);
        if (!locationFound) return;

        locationFound.checked = !locationFound.checked;

        if (locationFound.checked && !categoryFound.checked) {
          categoryFound.checked = true;
        }
      }
    } else {
      const userFound = associations.find((association) => `${association.id}` === event.target.name);
      userFound.checked = !userFound.checked;
    }

    setUpdateCheckboxes((prevState) => !prevState);
  };

  useEffect(() => { }, [updateCheckboxes]);

  return (
    <>
      {associations.length ? associations.map((user, index) => (
        <div
          key={user.id}
          className={styles.associationIndividual}
        >
          <div className={styles.locationsContainer}>
            {/* {!cluster ? (
              <FormControlLabel
                  className={styles.checkbox}
                  control={
                    <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                        name={`${user.id}`}
                        checked={associations[index].checked}
                        onChange={handleCheck}
                    />
                  }
                  label={(
                  <p>{`${user.fullName} - DNI ${user.dni}`}</p>
                  )}
                />
            ) : ( */}
            <p>{`${user.fullName} - DNI ${user.dni}`}</p>
            {/* )} */}
            {/* {user.locations.map((location, lindex) => (
                <FormControlLabel
                key={location.id}
                className={styles.checkbox}
                control={
                    <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    name={`${user.id}-${location.id}`}
                    checked={associations[index].locations[lindex].checked}
                    onChange={handleCheck}
                    />
                }
                label={(
                    <p>{`${location.name}, ${location.chain}`}</p>
                )}
                />
            ))} */}
            {user.categories.map((category, cindex) => (
              <Box
                key={category.id}
              >
                <FormControlLabel
                  className={styles.checkbox}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      name={`${user.id}-${category.id}`}
                      checked={associations[index].categories[cindex].checked}
                      onChange={(e) => handleCheck(e, 'category')}
                    />
                  }
                  label={(<p>{`${category.name}${category.code ? ` - ${category.code}` : ''}`}</p>)}
                />
                <br />
                <Box display="grid">
                  {category.locations.map((location, lindex) => (
                    <FormControlLabel
                      key={`${category.id}-${location.id}`}
                      className={styles.checkboxIndented}
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlank />}
                          checkedIcon={<CheckBox />}
                          name={`${user.id}-${category.id}-${location.id}`}
                          checked={associations[index].categories[cindex].locations[lindex].checked}
                          onChange={(e) => handleCheck(e, 'location')}
                        />
                      }
                      label={(<p>{`${location.name}${location.chain ? `, ${location.chain}` : ''}`}</p>)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </div>
        </div>
      )) : <p className={styles.emptyAssociations}>Aún no hay asociaciones</p>}
    </>
  );
}

export default TaskAssociationConfirm;
