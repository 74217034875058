import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
// icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
// components
import Pagination from '../../components/Pagination';
import Snackbar from '../../components/Snackbar';
import TableToolbarActions from '../../components/TableToolbarActions';

import useStyles from './styles';

// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';
// utils
import load from '../../utils/load';
import { handleSelect, isSelected } from '../../utils/selectFunctions';
// requests
import {
  getCategory,
  getCategoriesPerPage,
  deleteCategory,
} from '../../requests/api/category';

function CategoriesTable({
  categoryInfo, setCategoryInfo, setCurrentOpen,
  setReadOnly, table, handleSelectCategory,
  update, setUpdate, tableHeaders,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [categoriesQuantity, setCategoriesQuantity] = useState(0);

  // auxiliar states
  const [loading, setLoading] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // Edit category states
  // const [categoryInfo, setCategoryInfo] = useState(null);

  // snackbar
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const company = useSelector((state) => state.company);
  const account = useSelector((state) => state.account);

  // Saves the category information to be edited
  const handleEditShow = async (event, edit) => {
    const { id } = event.currentTarget;

    const response = await getCategory(id);

    if (edit) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }

    if (response.status === 200) {
      setCategoryInfo(response.data.data.category);
      setCurrentOpen(true);
    } else {
      setSeverity('error');
      setMessage(response.data.errorMessage);
    }
  };

  useEffect(() => {
    load(200, setLoading);
    // eslint-disable-next-line
  }, [categoryInfo, t, table]);

  useEffect(() => {
    const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
      const response = await getCategoriesPerPage(
        pageOfTable,
        limit,
        searchValue,
        columnName,
      );

      if (response.success) {
        let categoriesToSave = response.data.data.categories;
        if (table) {
          categoriesToSave = [{
            id: 'noCategory', name: t('task.noCategory'), companyName: 'N/A', code: 'N/A',
          }, ...categoriesToSave];
        }
        setRowsToShow(categoriesToSave);
        setCategoriesQuantity(response.data.data.totalCategories);
      }

      if (response.status === UNAUTHORIZED.status) {
        window.location.href = '/home';
      }

      if (Array.isArray(columnName)) setSearchText(searchValue);
    };

    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }

    requestSearch(page, rowsPerPage, filter, column);
    // eslint-disable-next-line
  }, [column, filter, page, rowsPerPage, account.user.role, update, company]);

  const content = () => {
    if (!categoriesQuantity) {
      return (
        <TableRow className={styles.noContent}>
          <TableCell colSpan={7} className={styles.categories}>
            <PeopleAltIcon />
            <h4>{t('categories.noContent')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow.map((row, index) => {
          const isItemSelected = isSelected(row.id, selected);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              id={`${row.name} (${row.code})`}
              selected={isItemSelected}
              onClick={table ? handleSelectCategory : () => {}}
            >
              {table ? null : (
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id, selected, setSelected)
                    }
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
              )}
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.code}</TableCell>
              {table ? null : (
                <>
                <TableCell align="center">
                  {row.deleted ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="right" className={styles.iconsCell}>
                  <IconButton
                    id={row.id}
                    aria-label="edit"
                    onClick={(e) => handleEditShow(e, true)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    id={row.id}
                    aria-label="view"
                    onClick={(e) => handleEditShow(e, false)}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableToolbarActions
              tab={undefined}
              selected={selected}
              setSelected={setSelected}
              rowsToShow={rowsToShow}
              setUpdate={setUpdate}
              setMessage={setMessage}
              setSeverity={setSeverity}
              searchText={searchText}
              setSearchText={setSearchText}
              setColumn={setColumn}
              setFilter={setFilter}
              placeholder={t('categories.tableToolbar.filterPlaceholder')}
              onlyDelete
              deleteFunction={deleteCategory}
              table={table}
            />
            <TableRow>
              {table ? null : (
                <TableCell
                  className={styles.tableHeader}
                  padding="checkbox"
                >
                </TableCell>
              )}
              {tableHeaders.map((tableHeader) => (
                <TableCell
                  key={tableHeader.id}
                  className={styles.tableHeader}
                  align={tableHeader.id === 'deleted' ? 'center' : 'left'}
                  padding="normal"
                >
                  {tableHeader.id === 'name' && selected.length
                    ? t('categories.categoryForm.selectedLegend', {
                      length: selected.length,
                    })
                    : tableHeader.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} className={styles.progress}>
                  <CircularProgress />
                  <h4>{t('categories.loading')}</h4>
                </TableCell>
              </TableRow>
            ) : (
              content()
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPage={setPage}
        page={page}
        rowsPerPage={rowsPerPage}
        quantity={categoriesQuantity}
      />
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </>
  );
}

export default CategoriesTable;
