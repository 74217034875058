/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
// icons
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import useStyles from './styles';

import Modal from '../../components/Modal';
import Select from '../../components/Select';

function QuestionLogic({
  open, setOpen, inputValues, setInputValues, view,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [logicOpen, setLogicOpen] = useState([]);
  const [otherCases, setOtherCases] = useState({});

  const handleSave = () => {
    setOpen(false);
  };

  const handleCheckOtherCases = (event) => {
    const { name } = event.target;

    const questionNumber = name.split('-')[0];
    const choiceNumber = name.split('-')[1];

    if (questionNumber in otherCases) {
      const newOtherCases = { ...otherCases };
      delete newOtherCases[questionNumber];
      setOtherCases(newOtherCases);
      return;
    }

    const newOtherCases = { ...otherCases };
    newOtherCases[questionNumber] = {
      limit: choiceNumber,
    };

    setOtherCases(newOtherCases);
  };

  const handleAddLogic = (event) => {
    const newInputValues = [...inputValues];
    const { id } = event.currentTarget;

    const index = newInputValues.findIndex((question) => `${question.identifier}` === id);
    if (index === -1) return;
    const totalChoices = newInputValues[index].questionChoices.length;
    for (let i = 0; i < totalChoices; i += 1) {
      if (newInputValues[index].questionLogic.length >= totalChoices) break;
      newInputValues[index].questionLogic.push({
        questionIdentifier: id,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: '',
      });
    }

    // setUpdate((prevState) => !prevState);
    const exists = logicOpen.includes(id);

    if (exists) return;

    setInputValues(newInputValues);
    setLogicOpen([...logicOpen, id]);
  };

  const handleRemoveAll = () => {
    const newInputValues = [...inputValues];
    for (let i = 0; i < newInputValues.length; i += 1) {
      const questionIdentifier = newInputValues[i].identifier;
      newInputValues[i].questionLogic = [
        {
          questionIdentifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: (parseInt(questionIdentifier, 10) + 1).toString(),
        },
      ];
    }
    setInputValues(newInputValues);
    setLogicOpen([]);
  };

  const handleDeleteLogic = (event) => {
    const newInputValues = [...inputValues];
    const { id } = event.currentTarget;
    const qIndex = newInputValues.findIndex((question) => `${question.identifier}` === id);
    newInputValues[qIndex].questionLogic = [
      {
        questionIdentifier: id,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: (parseInt(id, 10) + 1).toString(),
      },
    ];

    const oldLogicOpen = [...logicOpen];
    const newLogicOpen = oldLogicOpen.filter((l) => l !== id);
    setLogicOpen(newLogicOpen);
    setInputValues(newInputValues);
  };

  const handleSelectQuestionLogic = (event) => {
    const newInputValues = [...inputValues];
    const { name, value } = event.target;

    const [questionId, qLogicIndex, noMoreCases] = name.split('-');

    const qIndex = newInputValues.findIndex(
      (question) => `${question.identifier}` === questionId,
    );

    if (qIndex === -1) return;

    if (noMoreCases) {
      newInputValues[qIndex].questionLogic.forEach((ql, index) => {
        if (index > qLogicIndex) {
          ql.questionIdentifier = questionId;
          ql.questionChoiceIdentifier = null;
          ql.nextQuestionIdentifier = value;
        }
      });
    } else {
      newInputValues[qIndex].questionLogic[qLogicIndex].nextQuestionIdentifier = value;
    }

    setInputValues(newInputValues);
  };

  const handleSelectQuestionLogicOtherCases = (event) => {
    const newInputValues = [...inputValues];
    const { name, value } = event.target;
    const [questionId, qLogicIndex, noMoreCases] = name.split('-');

    const qIndex = newInputValues.findIndex(
      (question) => `${question.identifier}` === questionId,
    );

    if (qIndex === -1) return;

    if (noMoreCases) {
      // Filter question logics that already have logic
      const filteredQuestionLogics = newInputValues[qIndex].questionLogic
        .filter((ql) => [null, ''].includes(ql.questionChoiceIdentifier));

      // Filter question choices that are being used in question logics
      const usedQuestionChoiceIdentifiers = newInputValues[qIndex].questionLogic.map(
        (ql) => (ql.questionChoiceIdentifier
          ? ql.questionChoiceIdentifier.toString() : null),
      );
      const filteredChoices = newInputValues[qIndex].questionChoices
        .filter((qc) => !usedQuestionChoiceIdentifiers.includes(qc.identifier.toString()));

      // For each question choice, make sure a question logic exists with correct next question
      filteredChoices.forEach((questionChoice, index) => {
        if (index < filteredQuestionLogics.length) {
          filteredQuestionLogics[index].questionIdentifier = questionId;
          filteredQuestionLogics[index]
            .questionChoiceIdentifier = questionChoice.identifier.toString();
          filteredQuestionLogics[index].nextQuestionIdentifier = value;
        }
      });
    } else {
      newInputValues[qIndex].questionLogic[qLogicIndex].nextQuestionIdentifier = value;
    }

    setInputValues(newInputValues);
  };

  const handleSelectQuestionChoice = (event) => {
    const newInputValues = [...inputValues];
    const { name, value } = event.target;

    const [id, index] = name.split('-');

    const i = newInputValues.findIndex(
      (question) => question.identifier === id,
    );

    if (i !== -1) {
      newInputValues[i].questionLogic[index].questionChoiceIdentifier = value;
    }

    setInputValues(newInputValues);
  };

  const createSelectQuestionOptions = (identifier) => {
    const questionOptions = inputValues.filter((q) => `${q.identifier}` !== identifier);
    const formatedOptions = questionOptions.map((questionOption) => ({
      name: `${questionOption.identifier} ${questionOption.title}`,
      value: questionOption.identifier,
    }));

    formatedOptions.unshift({ name: t('task.questionLogic.select'), value: '' });
    formatedOptions.push({ name: t('task.questionLogic.end'), value: 'end' });

    return formatedOptions;
  };

  const createSelectQuestionChoiceOptions = (identifier) => {
    const index = inputValues.findIndex((question) => `${question.identifier}` === identifier);

    if (index === -1) return [];

    const formatedOptions = inputValues[index].questionChoices.map(
      (questionChoiceOption) => ({
        name: questionChoiceOption.title,
        value: questionChoiceOption.identifier.toString(),
      }),
    );

    formatedOptions.unshift({ name: t('task.questionLogic.select'), value: '' });

    return formatedOptions;
  };

  useEffect(() => {}, [otherCases]);

  useEffect(() => {
    const logic = inputValues.map((question) => question.questionLogic);
    const logicOpenIds = inputValues.map((question) => question.identifier);
    logic.forEach((questionLogic, index) => {
      if (questionLogic.length > 1) {
        setLogicOpen((prevState) => [...prevState, logicOpenIds[index]]);
      }
    });
  }, []);

  return (
    <Modal
        title={t('task.questionLogic.modalTitle')}
        defaultOpen={open}
        setCurrentOpen={setOpen}
    >
    <div className={styles.questionLogicContainer}>
        {inputValues.map((question) => (
            <div
                className={styles.questionContainer}
                key={question.identifier}
            >
                <div className={styles.questionAndButton}>
                    <div className={styles.question}>
                        <div className={styles.questionIdentifier}>
                            {question.identifier}
                        </div>
                        <p>
                            {question.title}
                        </p>
                    </div>
                    {!view && (logicOpen.includes(question.identifier) ? (
                      <Button
                        className={styles.closeAddLogicButton}
                        onClick={handleDeleteLogic}
                        id={question.identifier}
                      >
                        Eliminar lógica
                      </Button>
                    ) : (
                      <Button
                        id={question.identifier}
                        className={styles.addLogicButton}
                        onClick={handleAddLogic}
                        disabled={!question.questionChoices
                          || question.questionChoices.length === 0
                        }
                      >
                        {t('task.questionLogic.addLogicButton')}
                      </Button>
                    ))}
                </div>
                {logicOpen.includes(question.identifier) ? (
                  question.questionLogic
                    .slice(0, question.identifier.toString() in otherCases
                      ? otherCases[question.identifier].limit : question.questionLogic.length)
                    .map((questionLogic, index) => (
                    <div key={`${question.identifier}-${index}`}>
                      <div className={styles.detailConditions}>
                        <Select
                          name="conditional"
                          items={[{ name: 'Si...', value: 'if' }]}
                          value="if"
                          onChange={() => {}}
                          fullWidth
                        />
                        <div>
                          <Select
                            name="condition"
                            items={[{ name: 'es', value: 'equal' }]}
                            value="equal"
                            onChange={() => {}}
                            fullWidth
                          />
                          <Select
                            name={`${question.identifier}-${index}`}
                            items={question.questionChoices.length
                              ? createSelectQuestionChoiceOptions(question.identifier) : []}
                            value={questionLogic.questionChoiceIdentifier || ''}
                            onChange={handleSelectQuestionChoice}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className={styles.detailConditions}>
                        <p>{t('task.questionLogic.then')}</p>
                        <div>
                          <Select
                            name="jumpTo"
                            items={[{ name: 'Saltar a', value: 'jumpTo' }]}
                            value="jumpTo"
                            onChange={() => {}}
                            width="100px"
                          />
                          <Select
                            name={`${question.identifier}-${index}`}
                            items={createSelectQuestionOptions(question.identifier)}
                            value={Number.parseInt(questionLogic.nextQuestionIdentifier, 10)
                              >= inputValues.length + 1
                              || !questionLogic.nextQuestionIdentifier
                              ? '' : questionLogic.nextQuestionIdentifier}
                            onChange={handleSelectQuestionLogic}
                            fullWidth
                          />
                        </div>
                      </div>
                      {question.questionLogic.length - 1 === index ? null : (
                        <FormControlLabel
                          className={styles.checkbox}
                          control={
                            <Checkbox
                              name={`${question.identifier}-${index + 1}`}
                              icon={<CheckBoxOutlineBlank />}
                              checkedIcon={<CheckBox />}
                              onChange={handleCheckOtherCases}
                              checked={
                                otherCases[question.identifier]
                                && otherCases[question.identifier].limit === (index + 1).toString()
                              }
                            />
                          }
                          label="El resto de las alternativas conducen a la misma pregunta"
                        />
                      )}
                      {question.identifier.toString() in otherCases ? (
                        <div className={styles.detailConditions}>
                          <p>{t('task.questionLogic.otherCases')}<b>{t('task.questionLogic.jumpTo')}</b></p>
                          <Select
                            name={`${question.identifier}-${index}-noMoreCases`}
                            items={createSelectQuestionOptions(question.identifier)}
                            value={Number.parseInt(
                              question.questionLogic[question.questionLogic.length - 1]
                                .nextQuestionIdentifier, 10,
                            )
                              >= inputValues.length + 1
                              || !question.questionLogic[question.questionLogic.length - 1]
                                .nextQuestionIdentifier
                              ? '' : question.questionLogic[question.questionLogic.length - 1].nextQuestionIdentifier}
                            onChange={handleSelectQuestionLogicOtherCases}
                            fullWidth
                          />
                        </div>
                      ) : null}
                    </div>))
                ) : (
                  <div className={styles.detailLogic}>
                      <p>
                          {t('task.questionLogic.alwaysJumpTo')}
                      </p>
                      <Select
                          name={`${question.identifier}-0`}
                          items={createSelectQuestionOptions(question.identifier)}
                          value={Number.parseInt(
                            question.questionLogic[0].nextQuestionIdentifier, 10,
                          )
                            >= inputValues.length + 1
                            || !question.questionLogic[0].nextQuestionIdentifier
                            ? '' : question.questionLogic[0].nextQuestionIdentifier}
                          onChange={handleSelectQuestionLogic}
                          width="250px"
                      />
                  </div>
                )}
            </div>
        ))}
        {!view && <div className={styles.buttonsContainer}>
            <Button onClick={handleRemoveAll}>
                {t('task.questionLogic.removeAllButton')}
            </Button>
            <Button onClick={handleSave}>
                {t('task.questionLogic.saveButton')}
            </Button>
        </div>}
    </div>
    </Modal>
  );
}

export default QuestionLogic;
