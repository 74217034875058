/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  IconButton, TextField, Tooltip,
  Grid, List, ListItem, ListItemText,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';
import Filter2Icon from '@material-ui/icons/Filter2';

import clsx from 'clsx';

import Select from '../../Select';
import useStyles from '../styles';
import Button from '../../Button';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

import {
  EDIT_MODE, VIEW_MODE,
} from '../../../utils/const/taskModes';

function DropDownMenu({
  identifier, pages, info, setUpdate, handleDeleteQuestion, handleDuplicateQuestion,
  mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});
  const initialId = 1;
  const correct = ['1'];

  const [options, setOptions] = useState(info ? info.QuestionChoices
    : pages[identifier - 1].questionChoices || []);

  useEffect(() => {
    const currentInfo = info || pages[identifier - 1];

    if (currentInfo) {
      setImage({
        ...image,
        url: currentInfo.imageUrl,
        name: currentInfo.imageKey,
      });

      const emails = (currentInfo.QuestionEmails
          && currentInfo.QuestionEmails[0] > 0
          && currentInfo.QuestionEmails[0].emails)
          || (currentInfo.questionEmails
          && currentInfo.questionEmails[0] > 0
          && currentInfo.questionEmails[0].emails);

      if (emails) {
        setEmailsArray(emails.split(','));
      }

      setSendEmail(currentInfo.sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    if (id === 'title' || id === 'description' || id === 'imageKey'
      || id === 'sendEmail' || id === 'questionEmails') {
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        [id]: value,
        questionLogic: [{
          questionIdentifier: identifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
        }],
        questionAnswer: correct,
        imageUrl,
      };
    } else {
      const indexOption = options.findIndex((option) => option.identifier === id);

      if (indexOption !== -1) options[indexOption].title = value;

      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionChoices: options,
        questionAnswer: correct,
      };
    }
  };

  const handleAddOption = async () => {
    setShowInputs(true);
    if (options.length && !options[options.length - 1].title) return;

    const generatedId = initialId + options.length;
    await setOptions([
      ...options, {
        identifier: `${generatedId}`,
        title: '',
        type: 'text',
      }]);
    setShowInputs(false);
  };

  const parseOptions = () => {
    const lista = [];
    for (let i = 0; i < options.length; i += 1) {
      lista.push({
        name: options[i].title || t('task.taskEdition.dropDownMenuText.newOption'),
        value: options[i].title,
      });
    }
    return lista;
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            value={info ? info.title : pages[identifier - 1].title}
            InputProps={{ disableUnderline: true }}
            multiline
            inputProps={{ maxLength: 70 }}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
            placeholder={t(
              'task.taskEdition.dropDownMenuText.question',
            )}
          />
          <Select
            name="selected"
            items={parseOptions(options)}
            className={`${styles.description} ${styles.textfield}`}
            fullwidth
          />
          {
            showInputs
              ? <div key={options[options.length - 1].identifier}>
                <TextField
                  id={options[options.length - 1].identifier}
                  className={styles.option}
                  value={options[options.length - 1].title}
                  placeholder={`${t('task.taskEdition.multipleSelectionText.write')} ${t('task.taskEdition.multipleSelectionText.option')}`}
                  InputProps={{ disableUnderline: true }}
                  onChange={mode === EDIT_MODE ? handleOnChange : null}
                  disabled={mode === VIEW_MODE}
                />
              </div>
              : null
          }
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={handleAddOption}
                className={clsx({
                  [styles.hide]: info,
                })}
              >
                {
                  showInputs ? t('task.taskEdition.dropDownMenuText.sendNewOption')
                    : t('task.taskEdition.dropDownMenuText.addNewOption')
                }
              </Button>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('task.taskForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('task.taskForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <>
          <Tooltip title={t('task.taskEdition.duplicate')}>
            <IconButton
              id={identifier}
              onClick={handleDuplicateQuestion}
              className={styles.deleteButton}
            >
              <Filter2Icon />
            </IconButton>
          </Tooltip>
          <IconButton
            id={identifier}
            onClick={handleDeleteQuestion}
            className={styles.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default DropDownMenu;
