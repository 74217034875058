import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  Fade,
  Grid,
  Modal,
} from '@material-ui/core';
import useStyles from './styles';

import { parseDate } from '../../../utils/date';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
};

const SurveyVideoCarousel = ({
  questionResponseWithVideoList,
  handleSelectedQuestionResponse,
}) => {
  const styles = useStyles();

  const [loading, setLoading] = useState(true);
  const [listVideos, setListVideos] = useState([]);
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState('');

  const handleOpenVideo = (videoUrl) => {
    setVideo(videoUrl);
    setOpen(true);
  };

  useEffect(() => {
    const findSurveyResponseVideos = async () => {
      const videos = questionResponseWithVideoList.map((questionResponseWithVideoItem) => {
        questionResponseWithVideoItem.QuestionResponseVideos.map((questionResponseVideoItem) => {
          /* eslint-disable no-param-reassign */
          questionResponseVideoItem.title = questionResponseWithVideoItem.Question.title;
          return questionResponseVideoItem;
        }).flat();
        return questionResponseWithVideoItem.QuestionResponseVideos;
      }).flat();

      setListVideos(videos);
      setLoading(false);
    };
    findSurveyResponseVideos();
  }, []);

  function Item(props) {
    return (
      <Card className={styles.card} key={props.id}>
        <CardHeader
          title={props.title}
          subheader={parseDate(props.createdAt)}
          action={
            <Checkbox
              onClick={() => {
                handleSelectedQuestionResponse(props.questionResponseId);
              }}
            />
          }
        />
        <CardMedia
          onClick={() => handleOpenVideo(props.videoUrl)}
          className={styles.image}
          component={'video'}
          src={props.videoUrl}
          controls
        />
      </Card>
    );
  }

  const content = (row) => (
    <Grid item container spacing={3} md={10} key={row.id}>
      {Item(row)}
    </Grid>
  );

  return (
    <div>
      <Grid alignItems='center' spacing={1} container className={styles.container}>
        <Grid item xs={12}>
          <Carousel
            responsive={responsive}
            className={styles.carousel}
          >
          {loading ? (
            <div>
              <CircularProgress />
              <h4>Loading</h4>
            </div>
          ) : listVideos.map((row) => (
            content(row)
          ))}
          </Carousel>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.modal}
      >
        <Fade in={open}>
          <video src={video} alt="Video de respuesta" controls />
        </Fade>
      </Modal>
    </div>
  );
};

export default SurveyVideoCarousel;
