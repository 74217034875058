import XLSX from 'xlsx-js-style';

function createXLSXTemplate(instructions, templateName, templateColumns, templateSheetName) {
  const workbook = XLSX.utils.book_new();

  // Convert instructions and example data to sheets
  const instructionWs = XLSX.utils.aoa_to_sheet(instructions);
  const templateWs = XLSX.utils.aoa_to_sheet([templateColumns]);

  // Set column widths
  instructionWs['!cols'] = [{ wch: 16 }, { wch: 16 }];
  templateWs['!cols'] = templateColumns.map((col) => ({ wch: col.length + 5 }));

  // Apply styles
  instructionWs.A1.s = { font: { bold: true, sz: 14 } };
  instructionWs.A8.s = { font: { bold: true } };
  instructionWs.A9.s = { font: { italic: true } };
  instructionWs.B9.s = { font: { italic: true } };

  instructions.slice(1, 5).forEach((_, index) => {
    const cellAddress = `A${index + 2}`;
    if (instructionWs[cellAddress]) {
      instructionWs[cellAddress].s = { font: { italic: true } };
    }
  });

  // Append sheets to the workbook
  XLSX.utils.book_append_sheet(workbook, instructionWs, 'Instrucciones');
  XLSX.utils.book_append_sheet(workbook, templateWs, templateSheetName);

  return XLSX.writeFile(workbook, `TCG-template-${templateName}.xlsx`);
}

export default createXLSXTemplate;
