/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import {
  Button, Divider, FormControlLabel, Switch,
  IconButton, Grid, TextField,
} from '@material-ui/core';

import {
  DragIndicator, Edit, ExpandLess, ExpandMore,
  VisibilityOutlined, ReplyOutlined,
} from '@material-ui/icons';

import {
  DragDropContext, Droppable, Draggable,
} from 'react-beautiful-dnd';

import useStyles from './styles';

// const
import {
  DROP_DOWN_MENU, END_PAGE, LONG_ANSWER, SIMPLE_SELECTION,
  SATISFACTION, SHORT_ANSWER, MULTIPLE_SELECTION, WELCOME_PAGE,
  YES_NO_QUESTION, NUMERIC_ANSWER, DATE, NPS_QUESTION, CONTENT,
  MULTIPLE_IMAGE_QUESTION, SIGNATURE_QUESTION, BARCODE_QUESTION,
  RESUME_PAGE, DECIMAL_ANSWER, PRICE, VIDEO,
} from '../../../utils/const/pageTypes';

import { EDIT_MODE } from '../../../utils/const/taskModes';

import questionValidator from '../../../utils/questionsValidator';
import { IMAGE } from '../../../utils/const/questions';

import SelectPage from '../../../components/Pages/SelectPage';
import WelcomePage from '../../../components/Pages/WelcomePage';
import SimpleSelection from '../../../components/Pages/SimpleSelection';
import MultipleSelection from '../../../components/Pages/MultipleSelection';
import ShortAnswer from '../../../components/Pages/ShortAnswer';
import DropDownMenu from '../../../components/Pages/DropDownMenu';
import LongAnswer from '../../../components/Pages/LongAnswer';
import Satisfaction from '../../../components/Pages/Satisfaction';
import ImagesAnswer from '../../../components/Pages/ImageAnswer';
import NumericAnswer from '../../../components/Pages/NumericAnswer';
import Snackbar from '../../../components/Snackbar';
import DateAnswer from '../../../components/Pages/DateAnswer';
import QuestionLogic from '../../QuestionLogic';
import YesNoQuestion from '../../../components/Pages/YesNoQuestion';
import NpsQuestion from '../../../components/Pages/NpsQuestion';
import MultipleImageQuestion from '../../../components/Pages/MultipleImageQuestion';
import SignatureQuestion from '../../../components/Pages/SignatureQuestion';
import BarcodeQuestion from '../../../components/Pages/BarcodeQuestion';
import DecimalAnswer from '../../../components/Pages/DecimalAnswer';
import ResumePage from '../../../components/Pages/ResumePage';
import Price from '../../../components/Pages/Price';

// Survey components
import SettingsFormDialog from '../settingsFormDialog';

// requests
import { editSurvey } from '../../../requests/api/survey';
import Video from '../../../components/Pages/Video';

function EditTask({
  formType, project, title, setTitle, questions, taskId, setEditTask, taskInfo,
  setTaskId, setTaskInfo, setQuestions, setInputValues, vertical,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // Show/hide survey settings dialog
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

  // snackbar status
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [values, setValues] = useState({
    title,
    formType,
    project,
    questions: [],
    isSwitchStock: false,
    productInformation: false,
  });
  const [verticalForm, setVerticalForm] = useState(() => () => vertical);
  const [georeference, setGeoreference] = useState(taskInfo.requiredGeoreference);
  const [viewDetail, setViewDetail] = useState(null);
  const generalView = false;
  // const [generalView, setGeneralView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [switchStock, setSwitchStock] = useState(null);

  const [pages, setPages] = useState([
    {
      identifier: 0,
      type: null,
      name: '',
      required: false,
    },
  ]);

  // const [tab, setTab] = useState(0);
  const [selectedPage, setSelectedPage] = useState(null);
  const [openConnections, setOpenConnections] = useState(false);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [revisionPolicy, setRevisionPolicy] = useState({
    name: t('task.taskEdition.revisionPolicy.placeholder'),
    value: '',
  });

  useEffect(() => {
    setGeoreference(taskInfo.requiredGeoreference);
    setRevisionPolicy({
      name: t('task.taskEdition.revisionPolicy.placeholder'),
      value: taskInfo.revisionPolicy,
    });
  }, [taskInfo]);

  useEffect(() => {
    setVerticalForm(vertical);
  }, []);

  useEffect(() => {
    const parseQuestionChoices = (questionChoices) => {
      const optionsArray = [];
      let identifierChoice = 0;
      questionChoices.forEach((questionChoice) => {
        identifierChoice += 1;
        optionsArray.push({
          title: questionChoice.title,
          type: questionChoice.type,
          identifier: identifierChoice,
          id: questionChoice.id,
        });
      });

      return optionsArray;
    };

    const newPages = [...pages];
    for (let i = 0; i < questions.length; i += 1) {
      let memoryQuantity = null;
      let memoryUnit = null;
      if (questions[i].minMemory) {
        [memoryQuantity, memoryUnit] = questions[i].minMemory.split(' ');
      }

      if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(questions[i].type)) {
        const kpiDetailsPage = [];
        const shareKpi = {};
        questions[i].TeleauditKpis.forEach((teleauditKpi) => {
          if (teleauditKpi.KpiDetail.teleauditType === 'binary') {
            const kpi = {
              title: teleauditKpi.KpiDetail.name,
              description: teleauditKpi.KpiDetail.description,
              teleauditType: teleauditKpi.KpiDetail.teleauditType,
            };
            kpiDetailsPage.push(kpi);
          } else {
            shareKpi.teleauditType = teleauditKpi.KpiDetail.teleauditType;
            shareKpi.target = teleauditKpi.KpiDetail.target;
          }
        });

        newPages[i] = {
          ...newPages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          questionChoices: questions[i].QuestionChoices
            ? parseQuestionChoices(questions[i].QuestionChoices) : null,
          binaryKpis: kpiDetailsPage,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          required: questions[i].required || false,
          shareKpi,
          questionLogic: [{
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null, // revisar PENDIENTE
            nextQuestionIdentifier: `${(i + 2)}`,
          }],
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      } else {
        if (questions[i].isSwitchStock) {
          setSwitchStock(i + 1);
        }

        const sortedQuestionChoices = [...questions[i].QuestionChoices].sort(
          (a, b) => a.id - b.id,
        );
        const parseQuestionLogic = [];
        if (questions[i].OriginalQuestionLogics.length > 0) {
          questions[i].OriginalQuestionLogics.forEach((questionLogic) => {
            const nextId = questions.findIndex(
              (question) => question.id === parseInt(questionLogic.nextQuestionId, 10),
            );
            const questionChoiceIdentifier = sortedQuestionChoices.findIndex(
              (questionChoice) => questionChoice.id === questionLogic.questionChoiceId,
            );
            if (nextId > -1 && questionChoiceIdentifier > -1) {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: `${(questionChoiceIdentifier + 1)}`,
                nextQuestionIdentifier: questions[nextId].position.toString(),
              });
            } else if (nextId > -1) {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(nextId + 1)}`,
              });
            } else {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(i + 2)}`,
              });
            }
          });
        } else {
          parseQuestionLogic.push({
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null,
            nextQuestionIdentifier: `${(questions.length + 1)}`,
          });
        }

        newPages[i] = {
          ...newPages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          questionChoices: sortedQuestionChoices
            ? parseQuestionChoices(sortedQuestionChoices) : null,
          questionLogic: parseQuestionLogic,
          required: questions[i].required || false,
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          isSwitchStock: questions[i].isSwitchStock,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      }
    }

    setPages([...newPages]);
  }, [questions]);

  // Change the tool tab
  // const handleChangeTab = (event, newTab) => {
  //   setTab(newTab);
  // };

  // Select page
  const handleSelectPage = (event) => {
    const [type, identifier] = event.currentTarget.id.split('-');

    if (type === 'null') return;

    setSelectedPage(identifier);
  };

  // Adds a new page
  const handleAddSection = () => {
    // The last question now has a question logic to the new last question.
    const lastPage = pages[pages.length - 1];
    lastPage.questionLogic[lastPage.questionLogic.length - 1].nextQuestionIdentifier = `${pages.length + 1}`;

    const newQuestion = {
      identifier: `${pages.length + 1}`,
      type: null,
      questionLogic: [{
        questionIdentifier: `${(pages.length + 1)}`,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: null,
      }],
      required: false,
      databaseId: null,
    };

    setPages((prevPages) => [...prevPages, newQuestion]);
  };

  // Handles the status that indicates whether the question is required or not
  const handleSwitch = (event) => {
    setUpdate((prevState) => !prevState);
    const { name, checked } = event.target;

    pages[name].required = checked;
  };

  // Relocates changed pages in the array
  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const copiedItems = [...pages];
    for (let i = 0; i < copiedItems.length; i += 1) {
      copiedItems[i].hiddenIdentifier = `${(i + 1)}`;
    }
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    for (let j = 0; j < copiedItems.length; j += 1) {
      // Updates identifier question, ignore informative pages
      copiedItems[j].identifier = `${(j + 1)}`;

      const questionLogicCopiedItem = copiedItems[j].questionLogic;
      if (questionLogicCopiedItem) {
        for (let i = 0; i < questionLogicCopiedItem.length; i += 1) {
          if (questionLogicCopiedItem[i].questionChoiceIdentifier) {
            const newIdToNextQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].nextQuestionIdentifier,
            );
            const idQuestion = copiedItems.find(
              (ci) => ci.hiddenIdentifier === questionLogicCopiedItem[i].questionIdentifier,
            );

            if (newIdToNextQuestion && idQuestion) {
              // eslint-disable-next-line max-len
              copiedItems[j].questionLogic[i].nextQuestionIdentifier = newIdToNextQuestion.identifier;
              copiedItems[j].questionLogic[i].questionIdentifier = idQuestion.identifier;
            }
          } else {
            copiedItems[j].questionLogic[i].questionIdentifier = `${(j + 1)}`;
            copiedItems[j].questionLogic[i].nextQuestionIdentifier = `${(j + 2)}`;
          }
        }
      }

      if (copiedItems[j].questionChoices) {
        // Ensure questionChoices are sorted by identifier
        copiedItems[j].questionChoices = copiedItems[j].questionChoices.sort(
          (a, b) => a.identifier - b.identifier,
        );
      }
    }

    const switchStockIndex = copiedItems.findIndex((ci) => ci.isSwitchStock);

    if (switchStockIndex !== -1) {
      setSwitchStock(copiedItems[switchStockIndex].identifier, 10);
    }
    copiedItems.forEach((copiedItem) => {
      delete copiedItem.hiddenIdentifier;
    });
    setPages([
      ...copiedItems,
    ]);
  };

  const handleSaveSurvey = async () => {
    let revisionPolicyValidator = revisionPolicy.value;
    if (!revisionPolicyValidator) {
      setSeverity('error');
      setMessage(t('task.saveErrors.noRequiresRevision'));
      return;
    }

    if (values.isSwitchStock && switchStock == null) {
      setSeverity('error');
      setMessage(t('task.saveErrors.switchStockRequiredQuestion'));
      return;
    }

    const newQuestionsArray = [];

    let position = 0;
    let validator = 0;

    pages.forEach(async (page) => {
      if (validator !== 0) return;
      position += 1;

      if (!page.type) return;

      if (page.databaseId) {
        const updatedQuestion = {
          questionId: page.databaseId,
          identifier: page.identifier,
          surveyId: taskId,
          title: page.title,
          description: page.description,
          type: page.type,
          imageKey: page.imageKey,
          imageQuality: page.imageQuality,
          required: page.required,
          deleted: page.deleted,
          emojiScale: page.emojiScale,
          emojiSet: page.emojiSet,
          requiresTeleaudit: page.requiresTeleaudit,
          imageUrl: page.imageUrl,
          sendEmail: page.sendEmail,
          questionEmails: page.questionEmails,
          position,
          questionLogic: page.questionLogic,
          questionAnswer: page.questionAnswer || null,
          questionChoices: page.questionChoices || null,
          isSwitchStock: page.identifier === switchStock,
          minMemory: page.memoryQuantity && page.memoryUnit ? `${page.memoryQuantity} ${page.memoryUnit}` : null,
          videoQuality: page.videoQuality || null,
        };

        newQuestionsArray.push(updatedQuestion);
      } else {
        validator = questionValidator(page);
        if (validator !== 0) {
          setSeverity('error');
          switch (validator) {
            case 1:
              setMessage(`${t('task.saveErrors.title')} ${position}`);
              break;
            case 2:
              setMessage(`${t('task.saveErrors.binaryKpis')} ${position}`);
              break;
            default:
              setMessage(`${t('task.saveErrors.questionChoices')} ${position}`);
              break;
          }
          return;
        }
        // post new question
        const nextQuestionIdentifiers = page.questionLogic.map(
          (questionLogic) => questionLogic.nextQuestionIdentifier,
        );

        const index = nextQuestionIdentifiers.findIndex((nqi) => nqi === 'end' || nqi === '');

        if (index !== -1) {
          page.questionLogic[index].nextQuestionIdentifier = pages.length;
        }

        const baseQuestion = {
          identifier: page.identifier,
          surveyId: taskId,
          title: page.title,
          description: page.description,
          type: page.type,
          imageKey: page.imageKey,
          imageQuality: page.imageQuality,
          required: page.required,
          deleted: page.deleted,
          emojiScale: page.emojiScale,
          emojiSet: page.emojiSet,
          requiresTeleaudit: page.requiresTeleaudit,
          imageUrl: page.imageUrl,
          sendEmail: page.sendEmail,
          questionEmails: page.questionEmails,
          position,
          questionLogic: page.questionLogic,
          minMemory: page.memoryQuantity && page.memoryUnit ? `${page.memoryQuantity} ${page.memoryUnit}` : null,
          videoQuality: page.videoQuality || null,
        };

        if ([LONG_ANSWER, SHORT_ANSWER].includes(page.type)) {
          newQuestionsArray.push(baseQuestion);
        } else if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(page.type)) {
          if (page.type !== 'yes') {
            revisionPolicyValidator = 'yes';
          }

          const createdQuestion = {
            ...baseQuestion,
            binaryKpis: page.binaryKpis,
            shareKpi: page.shareKpi,
          };

          newQuestionsArray.push(createdQuestion);
        } else {
          const createdQuestion = {
            ...baseQuestion,
            questionAnswer: page.questionAnswer || null,
            questionChoices: page.questionChoices || null,
            isSwitchStock: page.identifier === switchStock,
          };

          newQuestionsArray.push(createdQuestion);
        }
      }
    });

    const body = {
      survey: {
        surveyId: taskId,
        title,
        requiredGeoreference: georeference,
        revisionPolicy: revisionPolicyValidator,
        vertical: verticalForm,
        switchStock: values.isSwitchStock,
        productInformation: values.productInformation,
      },
      questions: newQuestionsArray,
      questionsToDelete: deletedQuestions,
    };

    if (!validator) {
      await editSurvey(body);
      setTaskId(null);
      setTaskInfo({
        project: '',
        title: '',
        type: '',
      });
      setQuestions([]);
      setInputValues({
        title: '',
        type: { name: '', value: '' },
        project: { name: '', value: '' },
      });
      setEditTask(false);
    }
  };

  // const handleGeneralView = () => {
  //   setGeneralView((prevState) => !prevState);
  // };

  const handleViewQuestion = (event) => {
    const { id } = event.currentTarget;

    if (id === viewDetail) {
      setViewDetail(null);
      return;
    }

    setViewDetail(id);
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  useEffect(() => () => {
    setValues({
      ...values,
      questions: pages,
    });
  }, [pages, openConnections, update]);

  /**
   * Show/hide survey settings Dialog
   */
  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(!openSettingsDialog);
  };

  const handleBack = () => {
    setTaskId(null);
    setTaskInfo({
      project: '',
      title: '',
      type: '',
    });
    setQuestions([]);
    setInputValues({
      title: '',
      type: { name: '', value: '' },
      project: { name: '', value: '' },
    });
    setEditTask(false);
  };

  const handleDeleteQuestion = (event) => {
    const { id } = event.currentTarget;

    const copyPages = pages;
    const pageKeys = Object.keys(copyPages);

    if (copyPages[id - 1].databaseId !== undefined) {
      setDeletedQuestions([...deletedQuestions, copyPages[id - 1].databaseId]);
    }

    const newPages = [];

    pageKeys.forEach((pageIdentifier) => {
      if (pageIdentifier < (id - 1)) newPages.push(copyPages[pageIdentifier]);
      if (pageIdentifier > (id - 1)) {
        copyPages[pageIdentifier].identifier = pageIdentifier;
        const questionLogic = copyPages[pageIdentifier].questionLogic.find(
          (ql) => ql.questionChoiceIdentifier === null,
        );
        if (questionLogic) {
          questionLogic.questionIdentifier = pageIdentifier;
          questionLogic.nextQuestionIdentifier = (Number.parseInt(pageIdentifier, 10) + 1)
            .toString();
        }
        newPages.push(copyPages[pageIdentifier]);
      }
    });

    setPages(newPages);
  };

  /**
   * Duplicates a question and adds it to the end of the pages array
   * @param {Event} event - The event object
   */
  const handleDuplicateQuestion = (event) => {
    const { id } = event.currentTarget;
    const question = pages.find((p) => `${p.identifier}` === `${id}`);

    if (question) {
      const newQuestion = {
        ...question,
        identifier: `${pages.length + 1}`,
        hiddenIdentifier: `${pages.length + 1}`,
        position: `${pages.length + 1}`,
        questionLogic: [],
      };

      delete newQuestion.databaseId;
      if (question.questionChoices) {
        for (let i = 0; i < question.questionChoices.length; i += 1) {
          delete newQuestion.questionChoices[i].id;
        }
      }

      setPages([...pages, newQuestion]);
    }
  };

  const handleCreateConnections = () => {
    let errors = '';
    pages.forEach((page) => {
      const identifier = page.identifier === 0 ? 1 : page.identifier;
      const validator = questionValidator(page, formType.name);
      if (validator !== 0) {
        setSeverity('error');
        switch (validator) {
          case 1:
            errors += `${t('task.saveErrors.title')} ${identifier}\n`;
            break;
          case 2:
            errors += `${t('task.saveErrors.binaryKpis')} ${identifier}\n`;
            break;
          case 4:
            errors += `${t('task.saveErrors.correctQuestionChoices')} ${identifier}\n`;
            break;
          case 5:
            errors += `${t('task.saveErrors.questionType')} ${identifier}\n`;
            break;
          default:
            errors += `${t('task.saveErrors.questionChoices')} ${identifier}\n`;
            break;
        }
      }
    });

    if (errors === '') {
      setOpenConnections(true);
    } else {
      setMessage(errors);
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <IconButton
            id="backButton"
            aria-label="back"
            className={styles.backButton}
            onClick={handleBack}
          >
            <ReplyOutlined fontSize="small" />
            <span>{t('groups.actionButtons.back')}</span>
          </IconButton>
          <TextField
            id="name"
            className={styles.title}
            value={title}
            inputProps={
              {
                className: styles.title,
                disableUnderline: true,
              }
            }
            onChange={handleOnChangeTitle}
            fullWidth
          />
          <p>
            {taskInfo.project ? taskInfo.project.name : null}
          </p>
        </div>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleCreateConnections}
                disabled={questions.length < 2
                  || (questions.length === 2 && !questions[1].type)
                  || !questions[questions.length - 1].type}
              >
               {t('task.questionLogic.connections')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleOpenSettingsDialog}
              >
                {t('task.taskEdition.settings')}
              </Button>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleSaveSurvey}
                disabled={pages.length === 1 && !pages[0].type}
              >
                {t('task.taskEdition.save')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={styles.surveyContent}>
        <div className={styles.editionArea}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {pages.map((page, index) => (
                    <Draggable draggableId={`${index}`} index={index} key={index}>
                      {(prov) => (
                        <div
                          id={`${page.type}-${index}`}
                          onClick={handleSelectPage}
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                        >
                          <ExpandLess
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx(styles.pageInfo, {
                              [styles.selectedPageGeneralView]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                              [styles.generalViewPageTitle]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            <div>
                              {generalView && <DragIndicator />}
                              <p className={styles.pageTitle}>
                                {t('task.taskEdition.page')} {index + 1}
                                {page.type ? `: ${page.name}` : ''}
                              </p>
                            </div>
                            <div>
                              {page.type && generalView ? (
                                <>
                                  <Edit fontSize="small" />
                                  <Button
                                    id={index}
                                    onClick={handleViewQuestion}
                                  >
                                    <VisibilityOutlined className={styles.viewQuestion} />
                                  </Button>
                                </>
                              ) : null}
                              {page.type && !generalView
                              && ![WELCOME_PAGE, END_PAGE, CONTENT, RESUME_PAGE].includes(page.type)
                                ? (
                                <>
                                  <p className={styles.pageTitle}>{t('task.taskEdition.obligatory')}</p>
                                  <FormControlLabel
                                    control={
                                      <Switch name={`${index}`} className={styles.switch} onChange={handleSwitch} checked={page.required} />
                                    }
                                    label=""
                                  />
                                </>
                                ) : null}
                            </div>
                          </div>
                          <ExpandMore
                            className={clsx(styles.hideContent, {
                              [styles.selectedPageArrows]:
                                selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                            })}
                          />
                          <div
                            className={clsx({
                              [styles.selectedPageStyle]:
                                selectedPage === `${index}`,
                              [styles.hideContent]:
                                generalView && !(viewDetail === `${index}`),
                            })}
                          >
                            {([WELCOME_PAGE, END_PAGE, CONTENT].includes(page.type))
                              && <WelcomePage
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            }
                            {page.type === RESUME_PAGE && (
                              <ResumePage
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                              />
                            )}
                            {page.type === SIMPLE_SELECTION && (
                              <SimpleSelection
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === MULTIPLE_SELECTION && (
                              <MultipleSelection
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SHORT_ANSWER && (
                              <ShortAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                deletedQuestions={deletedQuestions}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === LONG_ANSWER && (
                              <LongAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === DROP_DOWN_MENU && (
                              <DropDownMenu
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SATISFACTION && (
                              <Satisfaction
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                editMode={true}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === IMAGE && (
                              <ImagesAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === YES_NO_QUESTION && (
                              <YesNoQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                                switchStock={switchStock}
                                setSwitchStock={setSwitchStock}
                              />
                            )}
                            {page.type === DATE && (
                              <DateAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === NUMERIC_ANSWER && (
                              <NumericAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === DECIMAL_ANSWER && (
                              <DecimalAnswer
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === NPS_QUESTION && (
                              <NpsQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === MULTIPLE_IMAGE_QUESTION && (
                              <MultipleImageQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === SIGNATURE_QUESTION && (
                              <SignatureQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === BARCODE_QUESTION && (
                              <BarcodeQuestion
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === PRICE && (
                              <Price
                                identifier={page.identifier}
                                pages={pages}
                                mode={EDIT_MODE}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {page.type === VIDEO && (
                              <Video
                                identifier={page.identifier}
                                mode={EDIT_MODE}
                                pages={pages}
                                setUpdate={setUpdate}
                                formType={formType}
                                handleDeleteQuestion={handleDeleteQuestion}
                                handleDuplicateQuestion={handleDuplicateQuestion}
                              />
                            )}
                            {!page.type && (
                              <SelectPage
                                setPages={setPages}
                                pages={pages}
                                mode={EDIT_MODE}
                                formType={formType}
                                identifier={page.identifier}
                                setSwitchStock={setSwitchStock}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            className={styles.button}
            onClick={() => handleAddSection()}
          >
            {t('task.taskEdition.addPageButton')}
          </Button>
        </div>
        {/* <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <StyledTabs
              value={tab}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <StyledTab label={t('task.taskEdition.tabOne')} />
              <StyledTab label={t('task.taskEdition.tabTwo')} />
            </StyledTabs>
            <div className={styles.toolsContent}>
              {tab === 1 ? (
                <div className={styles.connectionButton}>
                  <p>
                    <b>{t('task.questionLogic.content')}</b>
                    <br />
                    {t('task.questionLogic.connections')}
                  </p>
                  <IconButton
                    aria-label="connections"
                    onClick={handleCreateConnections}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              ) : null}
              {!selectedPage && tab !== 1 ? (
                <p>
                  <em>{t('task.taskEdition.toolsPlaceholder')}</em>
                </p>
              ) : null}
              <div className={styles.buttonsToolsBar}>
                <Button>
                  <VisibilityOutlined />
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleGeneralView}
                >
                  {generalView ? t('task.taskEdition.extendedViewButton')
                    : t('task.taskEdition.generalViewButton')}
                </Button>
              </div>
            </div>
          </Paper>
        </div> */}
      </div>
      {openConnections ? (
        <QuestionLogic
          open={openConnections}
          setOpen={setOpenConnections}
          inputValues={pages}
          setInputValues={setPages}
          edit={true}
          view={false}
        />
      ) : null}
      {openSettingsDialog
        ? <SettingsFormDialog
          openSettingsDialog={openSettingsDialog}
          setOpenSettingsDialog={setOpenSettingsDialog}
          georeference={georeference}
          setGeoreference={setGeoreference}
          revisionPolicy={revisionPolicy}
          setRevisionPolicy={setRevisionPolicy}
          verticalForm={verticalForm}
          setVerticalForm={setVerticalForm}
          formType={taskInfo.type}
        />
        : null}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default EditTask;
