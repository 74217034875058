import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

// core
import {
  Box,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

// components
import useStyles from './styles';
import {
  // getSurveyResponsesTeleaudit,
  // getSurveyResponsesReaudit,
  getSurveyResponseForTeleaudit,
  getSurveyResponseForReaudit,
} from '../../../requests/api/survey-response';
import { UNAUTHORIZED } from '../../../utils/const/http';
import SurveyQuestionBox from './questionsBox';

const QuestionResponsesTeleauditView = () => {
  const styles = useStyles();
  // const { t } = useTranslation();

  // table states
  // const page = 0;
  // const rowsPerPage = 10;
  const [data, setData] = useState([]);
  const [dataReaudit, setDataReaudit] = useState([]);
  const { taskResponseId } = useParams();

  const requestSearch = async () => {
    // const response = await getSurveyResponsesTeleaudit(
    //   { id: taskResponseId, page: pageOfTable, limit },
    // );
    const response = await getSurveyResponseForTeleaudit(taskResponseId);

    if (response.success) {
      setData(response.data.data.QuestionResponses);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const requestSearchReaudit = async () => {
    const response = await getSurveyResponseForReaudit(taskResponseId);

    if (response.success) {
      setDataReaudit(response.data.data.QuestionResponses);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  useEffect(() => {
    requestSearch();
    requestSearchReaudit();
  }, []);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <Box sx={{ width: '100%' }}>
            <SurveyQuestionBox
              questions={data}
              reaudit={false}
            />
          </Box>
        </div>
        <div className={styles.header}>
          <Box sx={{ width: '100%' }}>
            <SurveyQuestionBox
              questions={dataReaudit}
              reaudit={true}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default QuestionResponsesTeleauditView;
